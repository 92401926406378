import React, { useState, useEffect } from 'react'
import { Button, Spinner } from 'reactstrap';
import http from '../../CommonAxios'
import { checkData } from '../../ObjectExist';
import { Link } from 'react-router-dom';
import { changeIntoDatedmY } from '../../utlis';
import classnames from 'classnames';
import EventDetails from './EventDetails';
import parse from 'html-react-parser';
import NoData from '../../NoData';
import moment from 'react-moment';

const EventList = () => {
    const [loader, setLoader] = useState(false);
    const [apiData, setApiData] = useState({});
    const [listData, setListData] = useState({});
    const [activeTab, setActiveTab] = useState(1);
    const [detailsModal, setDetailsmodal] = useState(false);
    const [editData, setEditData] = useState({});
    const [eventDays, setEventDays] = useState([]);

    const toggleTab = tab => {
        setListData({})
        if (activeTab !== tab) setActiveTab(tab);
        let eventId = checkData(apiData) && checkData(apiData.data) && apiData.data[0].id
        let data = { event_id: eventId, day: tab }
        setLoader(true);
        http.post(`/event/front/item/list`, data).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {
                setListData(res.data.data)
            }
        }).catch(function (err) {
            setLoader(false);
        });
    }


    const toggleDetails = (id) => {
        setEditData({})
        setDetailsmodal(!detailsModal);
        let data = { admin_id: 1, id: id }
        setLoader(true);
        http.post(`event/item/view`, data).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {
                res.data.data[0].status === true ? res.data.data[0].status = '1' : res.data.data[0].status = '0'
                setEditData(res.data.data[0]);
            }
        }).catch(function (err) {
            setLoader(false);
        });
    }

    const onApiInteg = () => {
        let data = { admin_id: 1 }
        setLoader(true);
        http.post(`/event/front/list`, data).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {
                setApiData(res.data);
                let datas = { event_id: res.data.data[0].id, day: 1 }
                http.post(`/event/front/item/list`, datas).then((response) => {
                    if (res.data.s === 1) {
                        setListData(response.data.data)
                    }
                }).catch(function (err) {
                    setLoader(false);
                });
            }
        }).catch(function (err) {
            setLoader(false);
        });
    };
    useEffect(() => {
        onApiInteg();
    }, []);

    // const startDate = "02/20/2020";
    // const endDate = "03/01/2020";
    const startDate = checkData(apiData) && checkData(apiData.data) && apiData.data[0].event_start_date;
    const endDate = checkData(apiData) && checkData(apiData.data) && apiData.data[0].event_end_date;

    const diffTime = Math.abs(new Date(endDate) - new Date(startDate));
    const diffDays = 0 | diffTime / 864e5;

    var arr = []

    for (let i = 0; i <= diffDays; i++) {
        const newdate = new Date(new Date(startDate).getTime() + (i * 864e5));
        // console.log(newdate.toLocaleString('en-us', { day: '2-digit', month: 'short', weekday: 'long' }))
        let dateObj = { date: newdate.toLocaleString('en-us', { day: '2-digit', month: 'short', weekday: 'long' }), count: i + 1 }
        if (i === 0) {
            dateObj.days = "1st Day"
        } else if (i === 1) {
            dateObj.days = "2nd Day"
        } else if (i === 2) {
            dateObj.days = "3rd Day"
        } else if (i === 3) {
            dateObj.days = "4th Day"
        } else if (i === 4) {
            dateObj.days = "5th Day"
        } else {
            dateObj.days = i + 1
        }
        arr.push(dateObj)
    }
    const renderKeys = () => {
        return arr.map((val) => {
            return <button className={`${classnames({ active: activeTab === val.count })} tab-btn`} onClick={() => { toggleTab(val.count); }}>{val.days} <span className="date">{val.date}</span></button>
        });
    };
    //     let myDate = new Date("2015-06-17 14:24:36");
    // console.log("Date: "+moment(myDate).format('YYYY-MM-DD'));
    // console.log(moment("2022-12-17").format('DD-MM-YYYY'))

    return (
        <div>
            {loader && <div className="formLoader"><Spinner /></div>}
            {checkData(editData) &&
                <EventDetails addmodal={detailsModal} toggleAdd={toggleDetails} editData={editData} setDetailsmodal={setDetailsmodal} onApiInteg={onApiInteg} />
            }
            <div className="breadcumb-wrapper background-image">
                <img src='./img/Event-Schedule.jpg' alt="banner" className="w-100 img-fluid" />
            </div>
            <section className="bg-smoke space bg-bottom-right background-image" style={{ backgroundImage: 'url(./img/events-bg.png.jpg)' }}>
                <div className="container">
                    <div className="schedule-box-wrap">
                        <div className="nav tab-style2 mb-3" role="tablist">
                            {/* {renderKeys()} */}

                            {/* <button className={`${classnames({ active: activeTab === 'day1' })} tab-btn`} onClick={() => { toggleTab('day1'); }}>1st Day <span className="date">22 August, 2022</span></button>
                            <button className={`${classnames({ active: activeTab === 'day2' })} tab-btn`} onClick={() => { toggleTab('day2'); }}>2nd Day <span className="date">25 August, 2022</span></button>
                            <button className={`${classnames({ active: activeTab === 'day3' })} tab-btn`} onClick={() => { toggleTab('day3'); }}>3rd Day <span className="date">28 August, 2022</span></button> */}
                            {/* <span className="indicator" style={{ top: '0px', left: '0px', width: '100%', height: '131.391px' }}></span> */}
                        </div>
                        <div className="tab-content schedule-content-area" id="schedule-content-area">
                            <div className="tab-pane fade active show" id="nav-one" role="tabpanel" aria-labelledby="nav-one-tab">
                                {checkData(listData) ? listData.map((item, index) => (
                                    <div className="schedule-box">
                                        {/* <div className="schedule-box_date"><span className="month">Aug</span><span className="date">22</span></div> */}
                                        <div className="schedule-box_date">{changeIntoDatedmY(item.event_item_date)}</div>
                                        <div className="schedule-box_content">
                                            <h3 className="schedule-box_title"><a href="#">{item.title}</a></h3>
                                            <div className="schedule-meta">
                                                <span><i className="far fa-clock"></i>{item.start_end_time}</span>
                                                {/* <span><i className="fa fa-map-marker-alt"></i><a href="#">Marina Lounge</a></span> */}
                                            </div>
                                            <p className="schedule-box_text">{item.short_desc}</p>
                                            <p className="schedule-box_text">{parse(item.long_desc)}</p>
                                            <Button color="primary" className="btn-grad border-0 d-none" onClick={() => toggleDetails(item.id)}>View Details</Button>
                                        </div>
                                    </div>
                                )) : <h3 className="text-center">Event Coming Soon.</h3>}

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="space background-image shape-mockup-wrap my-3" style={{ backgroundImage: 'url(./img/cta_bg_3.jpg)' }}>
                <div className="container text-center">
                    <div className="title-area text-center mb-10">
                        <span className="sub-title ">Event Planner</span>
                        <h2 className="sec-title-ndm text-white">The Largest Annual Valuers Confluence in the world</h2>
                    </div>
                    <div className="event-info style2 mb-40">
                        <span className='d-none'><i className="fas fa-chair"></i>500 Seats</span>
                        <span className='d-none'><i className="fas fa-user"></i>45 Speakers</span>
                        <span>
                           <a href="https://www.google.com/maps/d/viewer?mid=1U8NwBvkM-5va85P8XZr3t_HLIsA_tOg&ll=26.146310677722912%2C91.74620054228158&z=11" target="_blank">
                             <i className="fas fa-location-dot"></i>
                             Kochi, India
                           </a>    
                        </span>
                        
                    </div>
                    <a href="/register" className="btn-grad-ndm">Register</a>
                </div>
                <div className="shape-mockup movingX d-none d-lg-block" style={{ top: '-42%', left: '0%' }}><img src="./img/circle-10.png" alt="shapes" /></div>
                <div className="shape-mockup jump-reverse" style={{ top: '-30%', left: '-12%' }}><img src="./img/circle-11.png" alt="shapes" /></div>
                <div className="shape-mockup rotate d-none d-lg-block" style={{ right: '-9%', bottom: '-33%' }}><img src="./img/circle-9.png" alt="shapes" /></div>
                <div className="shape-mockup jump" style={{ top: '25%', right: '12%' }}><img src="./img/triangle-12.png" alt="shapes" /></div>
            </section>
        </div>
    )
}

export default EventList