import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Card,
  CardHeader,
  CardTitle,
  Button,
  Spinner,
  Col,
  Form,
  FormGroup,
  CardBody,
  Table,
} from "reactstrap";
import http from "../../CommonAxios";
import { checkData, checkUndeNullBlank } from "../../ObjectExist";
import { changeIntoDateYmd } from "../../utlis";
import { useForm } from "react-hook-form";
import AddEventItem from "./AddEventItem";
import AddGallery from "./AddGallery";
import Swal from "sweetalert2";
import PermissionNotAllowed from "./PermissionNotAllowed";
import EditEventItem from "./EditEventItem";
import EditGallery from "./EditGallery";

const EventItem = (props) => {
  const [addmodal, setAddmodal] = useState(false);
  const [apiData, setApiData] = useState({});
  const [page, setPage] = useState(1);
  const [selectperpage, setSelectperpage] = useState(20);
  const [filterField, setFilterField] = useState({});
  const [currentCount, setCurrentCount] = useState(1);

  const [loader, setLoader] = useState(false);
  const [editmodal, setEditmodal] = useState(false);
  const [editData, setEditData] = useState({});

  const toggleAdd = () => setAddmodal(!addmodal);
  let eventId = new URLSearchParams(window.location.search).get("s");

  const {
    register: filterInput,
    handleSubmit: filterSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const toggleEdit = (id) => {
    setEditData({});
    setEditmodal(!editmodal);
    let data = { admin_id: checkData(props.user) ? props.user.id : "", id: id };
    setLoader(true);
    http
      .post(`event/item/view`, data)
      .then((res) => {
        setLoader(false);
        if (res.data.s === 1) {
          res.data.data[0].status === true
            ? (res.data.data[0].status = "1")
            : (res.data.data[0].status = "0");
          setEditData(res.data.data[0]);
        }
      })
      .catch(function (err) {
        setLoader(false);
      });
  };

  const onFilter = (data, pagePerpage) => {
    pagePerpage.page = 1;
    // if((checkUndeNullBlank(data.search_field) && checkUndeNullBlank(data.search_value))  || checkData(orderStatus)|| checkData(data.movement_type_id) || checkData(allCourier) || checkData(merchants) || checkData(data.delivery_type_id) || (checkData(filterDate.length) && filterDate.length > 0) || (checkData(eventDate.length) && eventDate.length > 0)){
    if (checkUndeNullBlank(data.title)) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterField(data);
      onCommonFilter(data, pagePerpage);
    } else {
      Swal.fire({ title: "info", text: "Please Choose Filter", icon: "info" });
    }
  };
  const onCommonFilter = (data, pagePerpage) => {
    let pageNumber =
      checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.page)
        ? pagePerpage.page
        : page;
    let perPageCount =
      checkUndeNullBlank(pagePerpage) &&
      checkUndeNullBlank(pagePerpage.per_page)
        ? pagePerpage.per_page
        : selectperpage;

    let byData = {
      title: data.title,
      event_id: parseInt(eventId),
      page: pageNumber,
      per_page: perPageCount,
    };
    byData.admin_id = checkData(props.user) ? props.user.id : "";
    setLoader(true);
    http
      .post(`event/item/list`, byData)
      .then((res) => {
        setLoader(false);
        //if (res.data.s === 1) {
        setApiData(res.data.data);
        //}
      })
      .catch(function (err) {
        setLoader(false);
      });
  };
  const formReset = () => {
    reset();
    onApiInteg();
  };

  const onApiInteg = () => {
    let data = {
      admin_id: checkData(props.user) ? props.user.id : "",
      event_id: parseInt(eventId),
    };
    setLoader(true);
    http
      .post(`event/item/list`, data)
      .then((res) => {
        setLoader(false);
        if (res.data.s === 1) {
          setApiData(res.data.data);
        }
      })
      .catch(function (err) {
        setLoader(false);
      });
  };
  useEffect(() => {
    onApiInteg();
  }, []);
  return (
    <div className="app-body overflow-hidden">
      {loader && (
        <div className="formLoader">
          <Spinner />
        </div>
      )}
      <AddEventItem
        user={props.user}
        addmodal={addmodal}
        toggleAdd={toggleAdd}
        eventId={eventId}
        setAddmodal={setAddmodal}
        onApiInteg={onApiInteg}
      />
      {checkData(editData) && (
        <EditEventItem
          user={props.user}
          editmodal={editmodal}
          toggleEdit={toggleEdit}
          eventId={eventId}
          editData={editData}
          setEditmodal={setEditmodal}
          onApiInteg={onApiInteg}
        />
      )}
      <Row>
        <Col sm={12}>
          <Card className="p-0 manageOrder shadow content-wrapper border-0">
            <Card className="border-0">
              <CardHeader className="page-header d-flex justify-content-between flex-wrap">
                <CardTitle className="page-title">Manage Event List</CardTitle>
                <div className="card-header-right d-flex flex-wrap align-items-center ml-auto">
                  {checkData(props.permissions) &&
                    props.permissions.Event_Items.add && (
                      <Button
                        color="primary"
                        className="btn-grad border-0"
                        onClick={toggleAdd}
                      >
                        Add Event List
                      </Button>
                    )}
                </div>
              </CardHeader>
              <Form onSubmit={filterSubmit(onFilter)} className="cardFilter">
                <Row className="mx-0 justify-content-start justify-content-lg-center">
                  <Col sm={6} md={6} lg={4} xl={3}>
                    <FormGroup>
                      <input
                        {...filterInput("title")}
                        className="form-control-sm form-control"
                        placeholder="Enter title*"
                        id="title"
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={3} className="btn-container">
                    <Button className="btn ctm-btn btn-sm" color="primary">
                      Search
                    </Button>
                    <Button
                      type="reset"
                      className="btn ctm-btn btn-sm mx-2"
                      color="primary"
                      outline
                      onClick={formReset}
                    >
                      Reset
                    </Button>
                  </Col>
                </Row>
              </Form>
              <CardBody className="p-0">
                <div className="showing-count">
                  {/* {checkData(allOrder) && checkData(allOrder.data) && checkUndeNullBlank(allOrder.TotalRows) ?
                                            <span class="small">Showing 1 of 20</span> : ''} */}
                </div>
                {checkData(props.permissions) &&
                props.permissions.Event_Items.list ? (
                  <Table responsive className="text-center custom-table">
                    <thead>
                      <tr>
                        <th className="text-nowrap">Day</th>
                        <th className="text-nowrap">title</th>
                        <th className="text-nowrap">Location</th>
                        <th className="text-nowrap">Event Date</th>
                        <th className="text-nowrap">Created At</th>
                        <th className="text-nowrap">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {checkData(apiData) ? (
                        apiData.map((item, index) => (
                          <tr>
                            <td>{item.day}</td>
                            <td>{item.title}</td>
                            <td>{item.location}</td>
                            <td>{item.event_item_date}</td>
                            <td>{changeIntoDateYmd(item.created_at)}</td>
                            <td>
                              {checkData(props.permissions) &&
                                props.permissions.Event_Items.edit && (
                                  <Button
                                    color="primary"
                                    className="vs-btn border-0"
                                    onClick={() => toggleEdit(item.id)}
                                  >
                                    Edit
                                  </Button>
                                )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={6}>
                            <center className="text-danger">
                              No Records Found
                            </center>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                ) : (
                  <PermissionNotAllowed />
                )}
              </CardBody>
            </Card>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default EventItem;
