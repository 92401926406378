import React, { useState,useEffect } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faWallet,faTimes, faBars, faDownload} from '@fortawesome/free-solid-svg-icons';
import { Navbar, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, Badge, Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Form } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Search, Settings, Power } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { encryptStorageLogin } from '../../EncStorage';

const Header = (props) => {
  const [openSearch, setOpenSearch] = useState(false);
  const navigate = useNavigate()

  const logOutUser = () => {
    encryptStorageLogin.clear();
    navigate('/')
  }

  useEffect(() => {
    props.permAPI();
    //console.log('aaaa',props)
  }, []);


  const { register: register2, handleSubmit: handleSubmit2, getValues } = useForm();
  const onSearchNo = (data) => {
    const awbOrderNo = getValues("awb_oder_no");
  }

  return (
    <div>
      <Navbar color="white" light expand="md" className="header-navbar topNav">
        <Nav className="mr-auto flex-row" navbar>
          <NavItem className="d-block d-lg-none">
            <NavLink href="#" className="text-center py-0 px-2"><i className="fas fa-bars"></i></NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="#" className="py-0 px-2 d-none" onClick={e => setOpenSearch(!openSearch)}><Search /></NavLink>
            <div className={`search-input ${openSearch ? "open d-none" : "d-none"}`}>
              <Form onSubmit={handleSubmit2(onSearchNo)}>
                <div className="search-input-icon">
                  <Search />
                </div>
                <div className="autocomplete-container">
                  <input type="text" {...register2('awb_oder_no')} class="autocomplete-search form-control" placeholder="search here..." />
                </div>
                <div class="search-input-close d-none" onClick={e => setOpenSearch(!openSearch)}>
                  <i className="fas fa-times"></i>
                </div>
              </Form>
            </div>
          </NavItem>
        </Nav>
        <div className="d-flex">
          {/* <Nav className="mr-auto align-items-center position-relative flex-row" navbar>              
              <NavItem>
                <NavLink href="#" className="text-center py-0 px-2">Recharge</NavLink>
              </NavItem>
              <NavItem>
                <Link to="/my-downloads" className="text-center py-0 px-2"><i className="fas fa-download"></i></Link>
              </NavItem>   
            </Nav>    */}
          <Nav className="flex-row">
            <NavItem>
              <NavLink href="#" className="py-0 px-0">
                <UncontrolledDropdown setActiveFromChild>
                  <DropdownToggle caret className="py-0 px-2 d-flex align-items-center btn-light">
                    <div className="user-nav d-sm-flex d-none flex-column align-items-end">
                      <span className="user-name font-weight-bold">{props.user.name}</span><span className="user-status">{props.user.email}</span></div>
                    <div className="avatar"><span className="avatar-status-online"></span></div>
                  </DropdownToggle>
                  <DropdownMenu className="mt-2 start-auto end-0">
                    {/* <Link to="/settings" className="dropdown-item"><span className="mr-2"><Settings /></span>Setting</Link>
                      <Link to="/passbook" className="dropdown-item"><span className="mr-2"><i className="fas fa-wallet"></i></span>My Passbook</Link>
                      <div class="dropdown-divider m-0"></div> */}
                    <NavLink href="/logout" className="dropdown-item" onClick={logOutUser}><span className="mr-2"><Power /></span>Logout</NavLink>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </NavLink>
            </NavItem>
          </Nav>
        </div>

      </Navbar>
    </div>
  );
}

export default Header;