import React from "react";
import { Link } from "react-router-dom";

const Features = () => {
  return (
    <section
      class="space-top space-extra-bottom"
      style={{ backgroundImage: "url(./img/bg-5.png)" }}
    >
      <div class="container">
        <div class="title-area text-center">
          {/* <span class="sub-title3">Get Started</span> */}
          <h2 class="sec-title-ndm1">Who should attend</h2>
          <div class="line"></div>
        </div>
        <div
          class="row vs-carousel slider-shadow"
          data-slide-show="3"
          data-arrows="true"
        >
          <div class="col-lg-4 col-md-6">
            <div class="price-box">
              <span style={{ fontSize: "24px" }} class="price-box_price">
                Financial & Corporate Sector
              </span>
              <ul class="price-box_list">
                <li>Bankers, Lenders, Financial Institutions</li>
                <li>NBFCs, Financial Service Providers</li>
                <li>Tax Authorities</li>
                <li>Corporations, Startups</li>
                <li>Investors, Industry Associations.</li>
                <li>Insurance Companies</li>
              </ul>
              {/* <Link to="/register" class="d-none vs-btn style2">Get Ticket</Link> */}
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="price-box ndm-padding">
              <span style={{ fontSize: "24px" }} class="price-box_price">
                Current and Prospective Valuers for all Asset Classes{" "}
              </span>
              <ul class="price-box_list">
                <li>Land & Building, Plant & Machinery </li>
                <li>Financial Assets, Intangibles, Marine </li>
                <li>Gems & Jewellery, Plantations, Mines</li>
                <li>Quarries, and Specialized Valuers</li>
              </ul>
              {/* <a href="/register" class="d-none vs-btn style2">Get Ticket</a> */}
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="price-box ndm1-padding">
              <span style={{ fontSize: "24px" }} class="price-box_price">
                Professionals & Academia
              </span>
              <ul class="price-box_list">
                <li>Advocates, CA, CS, CMAs</li>
                <li>MBAs, IPs, Engineers</li>
                <li>Architects, Insurance Surveyors </li>
                <li>Students and Faculty from Universities</li>
                <li>Colleges, and Institutes</li>
              </ul>
              {/* <a href="/register" class="d-none vs-btn style2">Get Ticket</a> */}
            </div>
          </div>
        </div>
      </div>
    </section>
    // <section class="space-shape background-image shape-mockup-wrap" data-overlay="black2" data-opacity="8" style={{backgroundImage: 'url(./img//feature_bg_1.jpg)'}}>
    //     <div class="container">
    //         <div class="title-area2 text-center">
    //             <span class="sub-title2 text-white">
    //                 Get Started In Minutes
    //                 <img class="title-shape" src="./img/title_shape.png" alt="title shape" />
    //             </span>
    //             <h2 class="sec-title text-white">Who should attend</h2>
    //         </div>
    //         <div class="row gy-40 justify-content-center">
    //             <div class="col-lg col-sm-auto">
    //                 <div class="feature-card">
    //                     <div class="feature-card_icon">
    //                         <img src="./img/feature_1.png" alt="icon" />
    //                     </div>
    //                     <h3 class="feature-card_title">Officials of Central & State Government</h3>
    //                     <p class="feature-card_text">
    //                     Insolvency Professionals (IPs), Agencies (IPAs) & Entities (IPEs) ,Liquidators ,Creditors- Financial & Operational,Advocates,Chartered Accountants, Company Secretaries, Cost Accountants, MBAs ,Engineers,Architects,Insurance Surveyors,Students & faculty members from Universities, Colleges & Institutes.
    //                     </p>
    //                 </div>
    //             </div>
    //             <div class="col-lg col-sm-auto">
    //                 <div class="feature-card">
    //                     <div class="feature-card_icon">
    //                         <img src="./img/feature_2.png" alt="icon" />
    //                     </div>
    //                     <h3 class="feature-card_title">Valuers & Prospective Valuers for all the Asset Classes (including but not limited to)</h3>
    //                     <p class="feature-card_text">Land & Building ,Plant & Machinery,Securities or Financial Assets,Intangibles,Marine,Gems & Jewellery,Tea, Coffee, Rubber & Cardamom Plantation,Agriculture,Plantations,Forests,Mines and Quarries,Life interest, reversions and interest in expectancy,Specialised Valuers such as Impex, Marine, Surveyor</p>
    //                 </div>
    //             </div>
    //             <div class="col-lg col-sm-auto">
    //                 <div class="feature-card">
    //                     <div class="feature-card_icon">
    //                         <img src="./img/feature_3.png" alt="icon" />
    //                     </div>
    //                     <h3 class="feature-card_title">Stakeholders</h3>
    //                     <p class="feature-card_text">Bankers,Lenders,Financial Institutions,NBFC's,
    //                     Financial Services Providers,Body Corporate,Start Ups,Investors,Industries,Associations viz., IBA, FICCI, ASSOCHAM, PHD Chambers etc. ,Income-Tax authorities,International Valuers Associations,Insurance Companies</p>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    //     <div class="shape-mockup jump z-index-3 d-none d-xl-block" style={{top: '34%', left: '3%',}}><img src="./img/plane_1.png" alt="shapes" /></div>
    //     <div class="shape-mockup shapePulse z-index-3 d-none d-xl-block" style={{right: '3%', bottom: '34%'}}><img src="./img/power_1.png" alt="shapes" /></div>
    //     <div class="section-before">
    //         <img src="./img/curved-shape-top.png" alt="shape" />
    //     </div>
    //     <div class="section-after">
    //         <img src="./img/curved-shape-bottom.png" alt="shape" />
    //     </div>
    // </section>
  );
};

export default Features;
