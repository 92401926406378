module.exports = {
    nonMemberCategory: {
        1: 'IBBI Registered Valuer', 
        //2: 'Sponsor Deligate', 
        3: 'Foreign Deligates', 
        4: 'Others'
    },
    Registertype: {
        1: 'Registeration', 
        2: 'Residential Registeration'
    },
    Usertype: {1:'Accompanying Delegate',2:"Delegate",3:"Guest",4:"Media",5:"Organiser",6:"Speaker",7:"Sponsor",8:"VIP",9:"Volunteer"},
    Activities: {1:'Day 1 - Entry',2:"Day 1 - Kit Handover",3:"Day 1 - Lunch",4:"Day 1 - Dinner",5:"Day 2 - Check-in",6:"Day 2 - Lunch",7:"Day 2 - Certificate"},
    Activities_1: {1:'Day 1 - Entry',2:"Day 1 - Kit Handover",3:"Day 1 - Lunch"},
    Activities_2: {4:"Day 1 - Dinner",5:"Day 2 - Check-in",6:"Day 2 - Lunch",7:"Day 2 - Certificate"},

    
    iovivcMetadata: {
        "/": {
            title: "Join the 55th Indian Valuers Congress 2024 in Kochi",
            description: "Explore the 55th Indian Valuers Congress 2024 in Kochi—connect, learn, and advance your expertise in valuation practices.",
            metaKeywords: ""
        },
        "/page/about-us": {
            title: "About Us - Indian Valuers Congress | IVC 2024",
            description: "Learn about the Indian Valuers Congress (IVC) and its mission to advance valuation standards and foster collaboration among professionals in India",
            metaKeywords: ""
        },
        "/ivc-event-schedule": {
            title: "IVC 2024 Event Schedule | Indian Valuers Congress",
            description: "View the complete event schedule for the 55th Indian Valuers Congress 2024. Plan your experience with sessions, workshops, and networking opportunities",
            metaKeywords: ""
        },
        "/event/gallery": {
            title: "Event Gallery | 55th Indian Valuers Congress (IVC) 2024",
            description: "Explore the vibrant gallery from the 55th Indian Valuers Congress, showcasing memorable moments, speakers, and events. Relive the highlights and connect with the community!",
            metaKeywords: ""
        },
        "/contact": {
            title: "Contact Us | Indian Valuers Congress (IVC) 2024",
            description: "Get in touch with us for inquiries about the Indian Valuers Congress 2024. We’re here to assist you with any questions or information you need",
            metaKeywords: ""
        },
        "/page/partners": {
            title: "Our Partners | Indian Valuers Congress (IVC) 2024",
            description: "Discover the esteemed partners of the Indian Valuers Congress 2024, showcasing collaboration with industry leaders and organizations dedicated to advancing valuation standards.",
            metaKeywords: ""
        },
        "/page/about-iov": {
            title: "About the Indian Institute of Valuers (IOV) | IVC 2024",
            description: "Learn about the Indian Institute of Valuers (IOV), its mission, vision, and commitment to enhancing valuation standards in India. Discover our initiatives and contributions to the profession",
            metaKeywords: ""
        },
        "/page/about-ivc": {
            title: "About the Indian Valuers Congress (IVC) | IVC 2024",
            description: "Discover the Indian Valuers Congress (IVC), its objectives, and contributions to the valuation profession. Learn how IVC fosters collaboration, knowledge sharing, and professional development in the field.",
            metaKeywords: ""
        },
        "/page/eminent-speakers": {
            title: "Eminent Speakers | Indian Valuers Congress (IVC) 2024",
            description: "Meet the esteemed speakers at the Indian Valuers Congress 2024, featuring industry leaders and experts sharing insights on valuation practices and advancements in the field.",
            metaKeywords: ""
        },
        "/page/theme-of-55th-ivc": {
            title: "Theme of the 55th Indian Valuers Congress (IVC) 2024",
            description: "Discover the theme of the 55th Indian Valuers Congress 2024, which focuses on enhancing valuation practices and fostering professional development in the industry. Join us in exploring key insights and discussions",
            metaKeywords: ""
        },
        "/page/thematic-issues": {
            title: "Thematic Issues | Indian Valuers Congress (IVC) 2024",
            description: "Delve into the thematic issues highlighted at the Indian Valuers Congress 2024, addressing essential topics and challenges in the valuation field. Engage with expert insights and contribute to meaningful discussions",
            metaKeywords: ""
        },
        "/page/conference-advisory-group-of-iov": {
            title: "Conference Advisory Group of IOV | Indian Valuers Congress 2024",
            description: "Learn about the Conference Advisory Group of the Indian Institute of Valuers, which plays a vital role in guiding and enhancing the quality of the Indian Valuers Congress.",
            metaKeywords: ""
        },
        "/page/about-kochi": {
            title: "About Kochi | Indian Valuers Congress (IVC) 2024",
            description: "Discover Kochi, the historic city hosting the 55th Indian Valuers Congress 2024. Learn about its attractions, culture, and significance in hosting this prestigious event.",
            metaKeywords: ""
        }
    }


}