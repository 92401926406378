import React, { useState, useEffect } from 'react'
import { Container, Row, Card, CardHeader, CardTitle, Button, Spinner } from 'reactstrap'

const Dashboard = () => {
    const [loader, setLoader] = useState(false);

    return (
        <div className="app-body overflow-hidden">
            {loader && <div className="formLoader"><Spinner /></div>}
            <Card className="border-0 shadow mb-3">
                <CardHeader className="page-header content-header d-flex flex-wrap justify-content-between align-items-center">
                    <CardTitle className="page-title mb-3 mb-sm-0" tag="h3">Dashbord</CardTitle>
                </CardHeader>
            </Card>
            <Row>
                
            </Row>
        </div>
    )
}

export default Dashboard