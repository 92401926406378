import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
  Spinner,
  FormGroup,
  Form,
  CardHeader,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalHeader,
  ModalBody,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  Label,
} from "reactstrap";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import http from "../../CommonAxios";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { DateRangePicker } from "rsuite";
import moment from "moment";
import { checkData, checkUndeNullBlank } from "../../ObjectExist";
import { changeIntoDateYmd } from "../../utlis";
import parse from "html-react-parser";

const EventDetails = (props) => {
  const [eventDetals, setEventDetals] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setEventDetals(props.editData);
  }, []);
  return (
    <>
      {loader && (
        <div className="formLoader">
          <Spinner />
        </div>
      )}
      <Modal
        isOpen={props.addmodal}
        toggle={props.addmodal}
        scrollable={true}
        size="xl"
        className="addModal"
      >
        <ModalHeader toggle={props.toggleAdd}>Event Details</ModalHeader>
        <ModalBody>
          {checkData(props.editData) ? (
            <section className="space pt-3">
              <div className="container">
                <div className="event-details">
                  <div className="event-slider-area">
                    <div
                      className="event-img-slider vs-carousel slick-initialized slick-slider"
                      id="eventimgslide"
                      data-slide-show="1"
                      data-md-slide-show="1"
                    ></div>
                    <div className="vs-icon-box d-none d-md-block">
                      <button
                        data-slick-prev="#eventimgslide"
                        className="icon-btn style-4 arrow-left"
                      >
                        <i className="far fa-arrow-left"></i>
                      </button>
                      <button
                        data-slick-next="#eventimgslide"
                        className="icon-btn style-4 arrow-right"
                      >
                        <i className="far fa-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                  <div className="row gy-30 align-items-center justify-content-between">
                    <div className="col-lg-7">
                      <div className="event-head">
                        <div className="date">
                          <span className="day">
                            {checkUndeNullBlank(props.editData.event_item_date)
                              ? props.editData.event_item_date
                              : ""}
                          </span>
                          {/* <span className="month">Aug</span> */}
                        </div>
                        <div className="title-info">
                          <h2 className="event-title">
                            {checkUndeNullBlank(props.editData.title)
                              ? props.editData.title
                              : ""}{" "}
                          </h2>
                          <p className="event-info">
                            <i className="fas fa-clock"></i>Time:{" "}
                            {checkUndeNullBlank(props.editData.start_end_time)
                              ? props.editData.start_end_time
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-auto">
                      <div className="btn-group d-none">
                        <a href="#" className="btn-grad">
                          Save On Calendar
                        </a>
                        <a href="shop-details.html" className="btn-grad">
                          Buy Tickets
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="event-content">
                    <p>
                      {checkUndeNullBlank(props.editData.short_desc)
                        ? props.editData.short_desc
                        : ""}
                    </p>
                    <p>
                      {parse(
                        checkUndeNullBlank(props.editData.long_desc)
                          ? props.editData.long_desc
                          : ""
                      )}
                    </p>
                  </div>
                  <div className="row d-none">
                    <div className="col-lg-4">
                      <div className="vs-info-box">
                        <h3 className="vs-info-box_title">Event Info</h3>
                        <ul className="vs-info-box_content">
                          <li>
                            <h5>Venue</h5>
                            <span>
                              258 Dancing Street, <br /> Avon MA 2322
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="vs-info-box">
                        <h3 className="vs-info-box_title">Details</h3>
                        <ul className="vs-info-box_content">
                          <li>
                            <h5>Date:</h5>
                            <span>
                              {changeIntoDateYmd(
                                props.editData.event_start_date
                              )}{" "}
                              -{" "}
                              {changeIntoDateYmd(props.editData.event_end_date)}
                            </span>
                          </li>
                          <li>
                            <h5>Time:</h5>
                            <span>9:00 am - 9:00 pm</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="vs-info-box">
                        <h3 className="vs-info-box_title">Organizer</h3>
                        <ul className="vs-info-box_content">
                          <li>
                            <h4 className="fw-semibold">David Smith</h4>
                            <h5>Email &amp; Phone:</h5>
                            <span>
                              david.smith@gmail.com <br /> +(259) 2156 2154
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            ""
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default EventDetails;
