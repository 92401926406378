import React, { useState, useRef } from 'react'
import { Row, Col, Card, CardBody, Button, CardTitle, Spinner, FormGroup, Form, CardHeader, Table, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, DropdownToggle, DropdownMenu, UncontrolledDropdown, Label } from 'reactstrap';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import http from '../../CommonAxios'
import Swal from 'sweetalert2';
import { useForm } from 'react-hook-form';
import JoditEditor from "jodit-react";

const AddEventItem = (props) => {
    const editor = useRef(null)
    const [content, setContent] = useState('')
    const [comments, setComments] = useState('');
    const [loader, setLoader] = useState(false);
    const { register: addInput, handleSubmit: addSubmit, formState: { errors }, reset } = useForm();
    const onaddEvent = (data) => {
        setLoader(true);
        let datas = {}
        datas.admin_id = 1
        datas.event_id = props.eventId
        datas.title = data.event_title
        datas.day = data.day
        datas.location = data.location
        datas.short_desc = data.event_small_desc
        datas.event_item_date = data.event_item_date
        datas.start_end_time = data.start_end_time
        datas.status = data.status
        datas.long_desc = content

        http.post(`event/item/add`, datas).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {
                Swal.fire({ title: 'Success', text: res.data.msg, icon: 'success', });
                setComments('')
                props.setAddmodal(false); props.onApiInteg(); reset();
            } else {
                Swal.fire({ icon: 'error', title: 'Error', text: res.data.message, });
            }
        }).catch(function (err) {
            setLoader(false);
        });
    };
    return (
        <>
            {loader && <div className="formLoader"><Spinner /></div>}
            <Modal isOpen={props.addmodal} toggle={props.addmodal} scrollable={true} size="lg" className='addModal'>
                <ModalHeader toggle={props.toggleAdd}>Add Events Item</ModalHeader>
                <ModalBody>
                    <Form onSubmit={addSubmit(onaddEvent)}>
                        <Row className="mx-0  addModal">
                            <Col sm={6}>
                                <FormGroup className="position-relative p-0">
                                    {/* <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span> */}
                                    <Label for="day">Select Day<span className="text-danger">*</span></Label>
                                    <select {...addInput("day", { required: 'Please Select Day' })} className="form-control-sm form-control">
                                        <option value="">Select Day</option>
                                        <option value="1">Day 1</option>
                                        <option value="2">Day 2</option>
                                        <option value="3">Day 3</option>
                                        <option value="4">Day 4</option>
                                    </select>
                                    {errors.day && <span className="text-danger d-block error">{errors.day.message}</span>}
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label for="event_title">Enter Event title<span className="text-danger">*</span></Label>
                                    <input {...addInput("event_title", { required: 'Please Enter Title' })} className="form-control-sm form-control" placeholder="Enter Event title*" id="eventtitle" />
                                    {errors.event_title && <span className="text-danger d-block error">{errors.event_title.message}</span>}
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label for="start_end_time">Enter Start Time<span className="text-danger">*</span></Label>
                                    <input {...addInput("start_end_time", { required: 'Please Enter Start Time' })} className="form-control-sm form-control" placeholder="Enter Start Time*" id="start_end_time" />
                                    {errors.start_end_time && <span className="text-danger d-block error">{errors.start_end_time.message}</span>}
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label for="location">Enter Location<span className="text-danger">*</span></Label>
                                    <input {...addInput("location", { required: 'Please Enter Title' })} className="form-control-sm form-control" placeholder="Enter Location*" id="location" />
                                    {errors.location && <span className="text-danger d-block error">{errors.location.message}</span>}
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label for="event_item_date">Enter Date<span className="text-danger">*</span></Label>
                                    <input {...addInput("event_item_date", { required: 'Please Enter Date' })} type="date" className="form-control-sm form-control" placeholder="Enter Date*" id="event_item_date" />
                                    {errors.event_item_date && <span className="text-danger d-block error">{errors.event_item_date.message}</span>}
                                </FormGroup>
                            </Col>
                            {/* <Col sm={6}>
                                <FormGroup>
                                    <Label for="uploadLogo_add">Upload Image</Label>
                                    <input {...addInput("bannerImage", { required: false })} type="file" onChange={handleFileInput} accept=".jpg, .jpeg, .png" className="form-control-sm form-control" id="uploadLogo_add" />
                                    <span className="text-info d-block">600/300</span>
                                    {errors.bannerImage && <span className="text-danger d-block error">{errors.bannerImage.message}</span>}
                                </FormGroup>
                            </Col> */}
                            {/* <Col sm={6}>
                                <FormGroup className='eventDateinput'>
                                    <Label for="uploadLogo_add">Date</Label>
                                    <DateRangePicker value={eventDate} onChange={date => { dateRangeChange(date) }} placeholder="Enter Date" format="dd-MM-yyyy" />
                                </FormGroup>
                            </Col> */}
                            <Col sm={6}>
                                <FormGroup>
                                    <Label for="small_description">Enter Small Description<span className="text-danger">*</span></Label>
                                    <textarea {...addInput("event_small_desc", { required: 'Please Enter small Description' })} rows={3} className="form-control-sm form-control" placeholder="Enter Small Description*" id="small_descrption" />
                                    {errors.event_small_desc && <span className="text-danger d-block error">{errors.event_small_desc.message}</span>}
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <FormGroup check className="pl-0">
                                        <Label>Status<span className="text-danger">*</span></Label>
                                        <div>
                                            <span className="form-check2 me-3">
                                                <input className="mx-2" {...addInput("status", { required: true })} type="radio" value="1" id="active_add" defaultChecked />
                                                <Label for="active_add">Active</Label>
                                            </span>
                                            <span className="form-check2">
                                                <input className="mx-2" {...addInput("status", { required: true })} type="radio" value="0" id="inactive_add" />
                                                <Label for="inactive_add">InActive</Label>
                                            </span>
                                        </div>
                                    </FormGroup>
                                </FormGroup>
                            </Col>
                            <Col sm={12}>
                                <FormGroup>
                                    <Label for="large_description">Add large Description<span className="text-danger">*</span></Label>
                                    <JoditEditor
                                        ref={editor}
                                        value={content}
                                        tabIndex={1}
                                        onBlur={newContent => setContent(newContent)}
                                        onChange={newContent => { }}
                                    />
                                </FormGroup>

                            </Col>
                            <Col sm={12} className="text-right">
                                <Button className="vs-btn border-0" color="primary">Save</Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}

export default AddEventItem