module.exports = {
  country: {
    1	: 'Honduras', 2	: 'United Arab Emirates', 3	: 'Djibouti', 4	: 'Seychelles', 5	: 'Antigua and Barbuda', 6	: 'Vietnam', 7	: 'Hungary', 8	: 'Tajikistan', 9	: 'Belarus', 10: 'Austria',
    11 :	'Fiji', 12 :	'DR Congo', 13 :	'Papua New Guinea', 14 :	'Serbia', 15 :	'Comoros', 16 :	'Israel', 17 :	'Switzerland', 18 :	'Turkey', 19 :	'Iran', 20 :	'Germany',
    21 :	'Togo', 22 :	'Holy See', 23 :	'Sierra Leone', 24 :	'Guyana', 25 :	'Andorra', 26 :	'Bhutan', 27 :	'Laos', 28 :	'Dominica', 29 :	'Paraguay', 30 :	'Thailand', 
    31 :	'Bulgaria',  32 :	'Libya',  33 :	'Solomon Islands',  34 :	'Lebanon',  35 :	'United Kingdom',  36 :	'Nicaragua',  37 :	'France',  38 :	'Kyrgyzstan',  39 :	'El Salvador',  40 :	'Montenegro',
    41 :	'Luxembourg', 42 :	'Italy', 43 :	'Turkmenistan', 44 :	'Tanzania', 45 :	'South Africa', 46 :	'Marshall Islands', 47 :	'Suriname', 48 :	'Singapore', 49 :	'Denmark', 50 :	'Cabo Verde',
    51 :	'Finland',  52 :	'Congo',  53 :	'Micronesia',  54 :	'Slovakia',  55 :	'Myanmar',  56 :	'Norway',  57 :	'Maldives',  58 :	'Kenya',  59 :	'Saint Kitts & Nevis',  60 :	'South Korea',
    61 :	'Oman',    62 :	'State of Palestine',    63 :	'Costa Rica',    64 :	'Colombia',    65 :	'Liberia',    66 :	'Ireland',    67 :	'Central African Republic',    68 :	'New Zealand',    69 :	'Spain',    70 :	'Mauritania',
    71 :	'Uganda', 72 :	'Argentina', 73 :	'Malta', 74 :	'Algeria', 75 :	'Sudan', 76 :	'Brunei', 77 :	'Ukraine', 78 :	'Panama', 79 :	'Kuwait', 80 :	'Croatia',
    81 :	'Moldova', 82 :	'Iraq', 83 :	'Georgia', 84 :	'Belize', 85 :	'Bahamas', 86 :	'Monaco', 87 :	'Afghanistan', 88 :	'Liechtenstein', 89 :	'Poland', 90 :	'Canada',
    91 :	'Morocco', 92 :	'Eritrea', 93 :	'Saudi Arabia', 94 :	'Uruguay', 95 :	'Iceland', 96 :	'San Marino', 97 :	'Uzbekistan', 98 :	'United States', 99 :	'Peru', 100 :	'Angola',
    101 :	'Bosnia and Herzegovina', 102 :	'Mongolia', 103 :	'Malaysia', 104 :	'Mozambique', 105 :	'Ghana', 106 :	'Vanuatu', 107 :	'Yemen', 108 :	'Armenia', 109 :	'Jamaica', 110 :	'Nepal',
    111 :	'Qatar', 112 :	'Albania', 113 :	'Barbados', 114 :	'Venezuela', 115 :	'Madagascar', 116 :	'Indonesia', 117 :	'Lithuania', 118 :	'Cameroon', 119 :	'Côte d Ivoire', 120 :	'North Korea',
    121 :	'Australia', 122 :	'Namibia', 123 :	'Niger', 124 :	'Gambia', 125 :	'Botswana', 126 :	'Gabon', 127 :	'Pakistan', 128 :	'Sao Tome & Principe', 129 :	'Lesotho', 130 :	'Sri Lanka',
    131 :	'Brazil', 132 :	'Burkina Faso', 133 :	'North Macedonia', 134 :	'Slovenia', 135 :	'Nigeria', 136 :	'Mali', 137 :	'Samoa', 138 :	'Guinea - Bissau', 139 :	'Romania', 140 :	'Malawi',
    141 :	'Chile', 142 :	'Latvia', 143 :	'Kazakhstan', 144 :	'Zambia', 145 :	'Saint Lucia', 146 :	'Palau', 147 :	'Guatemala', 148 :	'Ecuador', 149 :	'Syria', 150 :	'Netherlands',
    151 :	'Bahrain', 152 :	'Senegal', 153 :	'Cambodia', 154 :	'Bangladesh', 155 :	'Chad', 156 :	'Somalia', 157 :	'Zimbabwe', 158 :	'Russia', 159 :	'China', 160 :	'Equatorial Guinea',
    161 :	'Trinidad and Tobago', 162 :	'Estonia', 163 :	'Timor - Leste', 164 :	'Guinea', 165 :	'Rwanda', 166 :	'Mexico', 167 :	'Mauritius', 168 :	'Japan', 169 :	'Benin', 170 :	'Cyprus',
    171 :	'Kiribati', 172 :	'Burundi', 173 :	'Tunisia', 174 :	'Tuvalu', 175 :	'Bolivia', 176 :	'Eswatini', 177 :	'Belgium', 178 :	'Ethiopia', 179 :	'Haiti', 180 :	'Cuba',
    181 :	'Grenada', 182 :	'South Sudan', 183 :	'St.Vincent & Grenadines', 184 :	'Philippines', 185 :	'Dominican Republic', 186 :	'Nauru', 187 :	'Czech Republic(Czechia)', 188 :	'Tonga', 189 :	'Greece', 190 :	'Egypt',
    191 :	'Jordan', 192 :	'Portugal', 193 :	'Azerbaijan', 194 :	'Sweden', 195 :	'India'
  }
}