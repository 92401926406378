import React, { useState, useEffect } from 'react'
import { Form, Spinner, FormGroup, Label, Card, CardBody, Badge, TabContent, TabPane, } from 'reactstrap'
import http from '../../CommonAxios'
import Swal from 'sweetalert2';
import { useForm } from 'react-hook-form';
import { StateList } from '../../StateList';
import classnames from 'classnames';
import { makePaymentpayu } from './Payu';
import { Link } from 'react-router-dom';

const ResponseP = (props) => {
    let resMessage = (new URLSearchParams(window.location.search)).get("msg");
    let resStatus = (new URLSearchParams(window.location.search)).get("status");
    const [loader, setLoader] = useState(false);
    const [bgImage, setBgImage] = useState(false);

    //console.log('resMessage', resMessage, 'resStatus', resStatus);

    useEffect(() => {
        if (resStatus === 'success' || resStatus === 'captured ') {
          setBgImage("Registration-Successfull.jpg");
        
        } else {
            setBgImage("Transaction-failed.jpg");

        }
    });    
    return (
        <div>
            {loader && <div className="formLoader"><Spinner /></div>}

            <div className="breadcumb-wrapper" 
             style={{ backgroundImage: `url(${process.env.REACT_APP_BASE_URL}/img/${bgImage})` }}
            
            >
                    
                <div className="container">
                    <div className="breadcumb-content">
                        <h1 className="breadcumb-title">Response</h1>
                        <ul className="breadcumb-menu">
                            <li><a href="./">Home</a></li>
                            <li className="active">Response</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="space">
                <div className="container text-center">
                    {resStatus === 'success' || resStatus === 'captured ' ?
                        <h1 className="text-success success-title">Success</h1>
                        :
                        <h1 className="error-title">Error</h1>

                    }
                    {resStatus === 'success' || resStatus === 'captured ' ?
                        <>
                         <h2>Registration Successful</h2>
                         <p>Your registration for the 55th IVC 2024 is successful. For further information, please email contact.ivc@iov.co.in</p>
                        </>
                        :
                        <>
                         <h2>{resMessage}</h2>
                         <p>Your registration for the 55th IVC 2024 has failed. Please contact contact.ivc@iov.co.in for assistance.</p>
                        </>
                    }
                    {/* <span className="d-block mb-30">Sorry, but the page you are looking for does not existing</span> */}
                    <Link to="/" className="vs-btn"><i className="mr-5 fas fa-home-lg"></i> Back to Home</Link>
                </div>
            </div>
        </div>
    )
}

export default ResponseP