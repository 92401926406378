import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, Button, CardTitle, Spinner, FormGroup, Form, CardHeader, Table, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, DropdownToggle, DropdownMenu, UncontrolledDropdown, Label } from 'reactstrap';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import http from '../../CommonAxios'
import Swal from 'sweetalert2';
import { useForm } from 'react-hook-form';
import { DateRangePicker } from 'rsuite';
import moment from 'moment';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';

const EditEvent = (props) => {
    const [comments, setComments] = useState('');
    const [image, setImage] = useState("");
    const [loader, setLoader] = useState(false);
    const [eventDate, setEventDate] = useState([]);
    const [eventId, seteventId] = useState('');
    const dateRangeChange = (date) => {
        setEventDate(date);

    }
    useEffect(() => {
        reset(props.editData)
        setComments(props.editData.event_long_desc);
        seteventId(props.editData.id);
        setEventDate([new Date(props.editData.event_start_date), new Date(props.editData.event_end_date)])
    }, []);
    const handleFileInput = (e) => {
        setImage(e.target.files[0])
    }

    const { register: addInput, handleSubmit: addSubmit, formState: { errors }, reset } = useForm();
   
    const onaddEvent = (data) => {
        let sDate = checkData(eventDate) ? moment(eventDate[0]).format('YYYY-MM-DD') : '';
        let eDate = checkData(eventDate) ? moment(eventDate[1]).format('YYYY-MM-DD') : '';
        setLoader(true);
        let formData = new FormData();
        formData.append("admin_id", checkData(props.user) ? props.user.id : '');
        formData.append("id", eventId);
        formData.append("event_title", data.event_title);
        formData.append("location", data.location);
        formData.append("event_small_desc", data.event_small_desc);
        formData.append("status", data.status);
        formData.append("event_main_image", checkUndeNullBlank(image) ? image : checkUndeNullBlank(props.editData.event_main_image) ? props.editData.event_main_image: '');
        formData.append("event_start_date", sDate);
        formData.append("event_end_date", eDate);
        formData.append("event_long_desc", comments);

        http.post(`event/edit`, formData).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {
                Swal.fire({ title: 'Success', text: res.data.msg, icon: 'success', });
                setComments('')
                props.setEditmodal(false); props.onApiInteg(); reset();
            } else {
                Swal.fire({ icon: 'error', title: 'Error', text: res.data.message, });
            }
        }).catch(function (err) {
            setLoader(false);
        });
    };
    return (
        <>
            {loader && <div className="formLoader"><Spinner /></div>}
            <Modal isOpen={props.addmodal} toggle={props.addmodal} scrollable={true} size="lg" className='addModal'>
                <ModalHeader toggle={props.toggleAdd}>Edit Events</ModalHeader>
                <ModalBody>
                    <Form onSubmit={addSubmit(onaddEvent)}>
                        <Row className="mx-0  addModal">
                            <Col sm={6}>
                                <FormGroup>
                                    <Label for="event_title">Enter Event title<span className="text-danger">*</span></Label>
                                    <input {...addInput("event_title", { required: 'Please Enter Title' })} className="form-control-sm form-control" placeholder="Enter Event title*" id="eventtitle" />
                                    {errors.event_title && <span className="text-danger d-block error">{errors.event_title.message}</span>}
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label for="location">Enter Location<span className="text-danger">*</span></Label>
                                    <input {...addInput("location", { required: 'Please Enter Title' })} className="form-control-sm form-control" placeholder="Enter Location*" id="location" />
                                    {errors.location && <span className="text-danger d-block error">{errors.location.message}</span>}
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label for="uploadLogo_add">Upload Image</Label>
                                    <input {...addInput("bannerImage", { required: false })} type="file" onChange={handleFileInput} accept=".jpg, .jpeg, .png" className="form-control-sm form-control" id="uploadLogo_add" />
                                    <span className="text-info d-block">600/300</span>
                                    {errors.bannerImage && <span className="text-danger d-block error">{errors.bannerImage.message}</span>}
                                    {checkUndeNullBlank(props.editData.event_main_image) &&
                                        <div className='mt-2'>
                                            <span>
                                                <img className="img-fluid" src={process.env.REACT_APP_IMAGE_EVENT + props.editData.event_main_image} alt="partner" width="60" />
                                            </span>
                                        </div>
                                    }
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup className='eventDateinput'>
                                    <Label for="uploadLogo_add">Date</Label>
                                    <DateRangePicker value={eventDate} onChange={date => { dateRangeChange(date) }} placeholder="Enter Date" format="dd-MM-yyyy" />
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label for="small_description">Enter Small Description<span className="text-danger">*</span></Label>
                                    <textarea {...addInput("event_small_desc", { required: 'Please Enter small Description' })} rows={3} className="form-control-sm form-control" placeholder="Enter Small Description*" id="small_descrption" />
                                    {errors.event_small_desc && <span className="text-danger d-block error">{errors.event_small_desc.message}</span>}
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <FormGroup check className="pl-0">
                                        <Label>Status<span className="text-danger">*</span></Label>
                                        <div>
                                            <span className="form-check2 me-3">
                                                <input className="mx-2" {...addInput("status", { required: true })} type="radio" value="1" id="active_add" />
                                                <Label for="active_add">Active</Label>
                                            </span>
                                            <span className="form-check2">
                                                <input className="mx-2" {...addInput("status", { required: true })} type="radio" value="0" id="inactive_add" />
                                                <Label for="inactive_add">InActive</Label>
                                            </span>
                                        </div>
                                    </FormGroup>
                                </FormGroup>
                            </Col>
                            <Col sm={12} className="text-right">
                                <Button className="vs-btn border-0" color="primary">Save</Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}

export default EditEvent