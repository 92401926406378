import {  Table } from 'reactstrap'

const PermissionNotAllowed = () => {
    return (
        <Table responsive className="text-center custom-table">
        <thead>
                <tr>
                    <th className="text-center text-danger">Permission Not Allowed</th>
                </tr>
        </thead>
     </Table>
    )
}

export default PermissionNotAllowed