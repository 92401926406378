import React from "react";
import { Spinner } from "reactstrap";

const Direction = () => {
  return (
    <section class="space-top space-extra-bottom">
      {/* {loader && <div className="formLoader"><Spinner /></div>} */}
      <div class="container">
        <div class="title-area2 text-center">
          <span class="sub-title2">
            Our Event Location
            <img
              class="title-shape"
              src="./img/title_shape.png"
              alt="title shape"
            />
          </span>
          <h2 class="sec-title-ndm">Get Direction To The Venue</h2>
        </div>
        <div class="row">
          <div class="col-xl-4 col-lg-5 mb-35 mb-lg-0">
            <div
              class="location-card-wrap background-image"
              style={{ backgroundImage: "url(./img/Venue-backgruond.jpg)" }}
            >
              <div
                class="vs-carousel slick-initialized slick-slider"
                data-asnavfor="#mapSlide"
                id="locationSlide"
                data-fade="true"
                data-slide-show="1"
                data-md-slide-show="1"
              >
                <div class="slick-list draggable">
                  <div
                    class="slick-track"
                    style={{ opacity: 1, width: "574px" }}
                  >
                    <div
                      class="location-card slick-slide slick-current slick-active"
                      style={{
                        width: "287px",
                        position: "relative",
                        left: "0px",
                        top: "0px",
                        zIndex: 999,
                        opacity: 1,
                      }}
                      tabindex="0"
                      data-slick-index="0"
                      aria-hidden="false"
                    >
                      <h3 class="location-title">Venue</h3>
                      <div class="location-info">
                        <span class="info-title">
                          Gokulam Park Hotel & Convention Centre
                        </span>
                        <p class="info-text">
                          Banerji Road, Kaloor, <br></br>Kochi, Kerala - 682017
                        </p>
                      </div>

                      <a
                        // href="https://www.google.com/maps/d/viewer?mid=1U8NwBvkM-5va85P8XZr3t_HLIsA_tOg&ll=26.146310677722912%2C91.74620054228158&z=11"
                        href="https://www.google.com/maps/place/Gokulam+Park+Hotel+Kochi/@9.9928964,76.2880025,1641m/data=!3m1!1e3!4m9!3m8!1s0x3b080d41b9b18193:0x129b8d73e4c31739!5m2!4m1!1i2!8m2!3d9.9936814!4d76.2904737!16s%2Fg%2F1tc_jktx?hl=en&entry=ttu"
                        target="_blank"
                        class="vs-btn btn-white"
                        tabindex="0"
                      >
                        Get Directions
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="vs-icon-box d-none">
                <button
                  data-slick-prev="#locationSlide"
                  class="simple-icon arrow-left"
                >
                  <i class="fa fa-arrow-left"></i>
                </button>
                <button
                  data-slick-next="#locationSlide"
                  class="simple-icon arrow-right"
                >
                  <i class="fa fa-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="col-xl-8 col-lg-12 justify-content-center d-flex">
            <div class="map-slide-wrap col-xl-11">
              <div
                class="vs-carousel slick-initialized slick-slider"
                data-asnavfor="#locationSlide"
                id="mapSlide"
                data-fade="true"
                data-slide-show="1"
                data-md-slide-show="1"
              >
                <div class="slick-list draggable">
                  <div
                    class="slick-track"
                    style={{ opacity: 1, width: "1608px" }}
                  >
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3775.7942554918873!2d76.2898889!3d9.993563!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b080d41b9b18193%3A0x129b8d73e4c31739!2sGokulam%20Park%20Hotel%20Kochi!5e1!3m2!1sen!2sin!4v1719570391615!5m2!1sen!2sin"
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                      class="slick-slide slick-current slick-active"
                      style={{
                        width: "804px",
                        height: "378px",
                        position: "relative",
                        left: "0px",
                        top: "0px",
                        zIndex: 999,
                        opacity: 1,
                      }}
                      tabindex="0"
                      data-slick-index="0"
                      aria-hidden="false"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Direction;
