import React, { useState } from "react";
import { Form, Spinner } from "reactstrap";
import http from "../../CommonAxios";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";

const Contact = (props) => {
  const [loader, setLoader] = useState(false);

  const {
    register: contactInput,
    handleSubmit: contactSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const onContactus = (data) => {
    setLoader(true);
    http
      .post(`contact/save`, data)
      .then((res) => {
        setLoader(false);
        if (res.data.s === 1) {
          Swal.fire({ icon: "success", title: "Success", text: res.data.msg });
          reset();
        } else {
          Swal.fire({ icon: "error", title: "Error", text: res.data.message });
        }
      })
      .catch(function (err) {
        setLoader(false);
      });
  };
  return (
    <div>
      {loader && (
        <div className="formLoader">
          <Spinner />
        </div>
      )}
      <div class="breadcumb-wrapper">
        <img
          src="./img/Contact-Us-Banner.jpg"
          alt="banner"
          className="w-100 img-fluid"
        />
      </div>
      <section
        class="bg-smoke space bg-auto-top-left"
        style={{ backgroundImage: "url(./img/contact_bg_1.jpg)" }}
      >
        <div class="container">
          <div class="contact-area">
            <div class="contact-box-wrapper d-none">
              <div class="row gy-20">
                <div class="col-lg-4">
                  <div class="contact-box">
                    <h4 class="contact-box_title">Main Office</h4>
                    <span class="contact-box_text">
                      27 NW New Vexmont, Portland, <br /> Oregon 97209
                    </span>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="contact-box">
                    <h4 class="contact-box_title">Call Us Directly</h4>
                    <span class="contact-box_text">
                      <a href="tel:+256215621456">+(256) 2156 21456</a> <br />{" "}
                      <a href="tel:+256215621420">+(256) 2156 21420</a>
                    </span>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="contact-box">
                    <h4 class="contact-box_title">Opening Hours</h4>
                    <span class="contact-box_text">
                      Monday - Friday: 8:30am - 6pm <br /> Saturday: 10am - 2pm
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13999.73123201181!2d77.1062192!3d28.6916565!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d023225555555%3A0x5a49065260d6782e!2sInstitution%20of%20Valuers%20%7C%20IOV!5e0!3m2!1sen!2sin!4v1719836425015!5m2!1sen!2sin"
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        <div class="space-top">
          <div class="container">
            <div class="title-area text-center">
              <h2 class="sec-title">Contact Us</h2>
              <span class="sub-2">Get In Touch</span>
              <span class="shape-title"></span>
            </div>
            <div class="row">
              <div class="col-xl-5 col-lg-6">
                <div class="contact-tab-wrapper">
                  <div class="nav tab-style5" id="tab-style5" role="tablist">
                    <button
                      class="tab-btn active"
                      id="nav-one-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-one"
                      type="button"
                      role="tab"
                      aria-controls="nav-one"
                      aria-selected="true"
                    >
                      Address
                    </button>
                  </div>
                  <div
                    class="tab-content contact-tab-area"
                    id="contact-tab-area"
                  >
                    <div
                      class="tab-pane fade show active"
                      id="nav-one"
                      role="tabpanel"
                      aria-labelledby="nav-one-tab"
                    >
                      <div class="contact-tab-content">
                        <i class="fas fa-ticket"></i>
                        <h3 class="contact-tab_title">Address</h3>
                        <ul>
                          <li>
                            <strong>Address: </strong>
                            {props.tempObj.address}
                          </li>
                          <li>
                            <strong>Phone: </strong>
                            {props.tempObj.phone}
                          </li>
                          <li>
                            <strong>Email: </strong>
                            {props.tempObj.email_for_contact}
                          </li>
                        </ul>
                        <div class="follow-card">
                          <span class="card-title">Follow Us:</span>
                          <div class="simple-social">
                            <a href={props.tempObj.facebook} target="_blank">
                              <i class="fab fa-facebook-f"></i>
                            </a>
                            <a href={props.tempObj.twitter_url} target="_blank">
                              <i class="fab fa-twitter"></i>
                            </a>
                            {/* <a href="#"><i class="fab fa-instagram"></i></a> */}
                            <a href={props.tempObj.linkedin} target="_blank">
                              <i class="fab fa-linkedin-in"></i>
                            </a>
                            {/* <a href="#"><i class="fab fa-pinterest-p"></i></a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="nav-two"
                      role="tabpanel"
                      aria-labelledby="nav-two-tab"
                    >
                      <div class="contact-tab-content">
                        <i class="fas fa-info-circle"></i>
                        <h3 class="contact-tab_title">Brand Info</h3>
                        <ul>
                          <li>
                            <strong>Name: </strong>Evona
                          </li>
                          <li>
                            <strong>Phone: </strong>+(256) 2597 2456
                          </li>
                          <li>
                            <strong>Email: </strong>info@evona.com
                          </li>
                        </ul>
                        <div class="follow-card">
                          <span class="card-title">Follow Us:</span>
                          <div class="simple-social">
                            <a href="#">
                              <i class="fab fa-facebook-f"></i>
                            </a>
                            <a href="#">
                              <i class="fab fa-twitter"></i>
                            </a>
                            <a href="#">
                              <i class="fab fa-instagram"></i>
                            </a>
                            <a href="#">
                              <i class="fab fa-linkedin-in"></i>
                            </a>
                            <a href="#">
                              <i class="fab fa-pinterest-p"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="nav-three"
                      role="tabpanel"
                      aria-labelledby="nav-three-tab"
                    >
                      <div class="contact-tab-content">
                        <i class="fas fa-calendar-star"></i>
                        <h3 class="contact-tab_title">Event Info</h3>
                        <ul>
                          <li>
                            <strong>Name: </strong>Tech Meetup
                          </li>
                          <li>
                            <strong>Phone: </strong>+(256) 2597 40053
                          </li>
                          <li>
                            <strong>Email: </strong>support@evona.com
                          </li>
                        </ul>
                        <div class="follow-card">
                          <span class="card-title">Follow Us:</span>
                          <div class="simple-social">
                            <a href="#">
                              <i class="fab fa-facebook-f"></i>
                            </a>
                            <a href="#">
                              <i class="fab fa-twitter"></i>
                            </a>
                            <a href="#">
                              <i class="fab fa-instagram"></i>
                            </a>
                            <a href="#">
                              <i class="fab fa-linkedin-in"></i>
                            </a>
                            <a href="#">
                              <i class="fab fa-pinterest-p"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-7 col-lg-6">
                <Form
                  onSubmit={contactSubmit(onContactus)}
                  class="ajax-contact contact-form"
                >
                  <div class="row gx-20">
                    <div class="form-group col-md-6">
                      <input
                        {...contactInput("name", {
                          required: "Please Enter Name",
                        })}
                        class="form-control"
                        id="name"
                        placeholder="Your Name"
                      />
                      {errors.name && (
                        <span className="text-danger d-block error">
                          {errors.name.message}
                        </span>
                      )}
                    </div>
                    <div class="form-group col-md-6">
                      <input
                        {...contactInput("email", {
                          required: "Please Enter Email",
                        })}
                        type="email"
                        class="form-control"
                        id="email"
                        placeholder="Your Email"
                      />
                      {errors.email && (
                        <span className="text-danger d-block error">
                          {errors.email.message}
                        </span>
                      )}
                    </div>
                    <div class="form-group col-md-6">
                      <input
                        {...contactInput("phone", {
                          required: "Please Enter Phone",
                        })}
                        type="number"
                        class="form-control"
                        id="number"
                        placeholder="Phone Number"
                      />
                      {errors.phone && (
                        <span className="text-danger d-block error">
                          {errors.phone.message}
                        </span>
                      )}
                    </div>
                    <div class="form-group col-md-6">
                      <input
                        {...contactInput("subject", {
                          required: "Please Enter Subject",
                        })}
                        class="form-control"
                        id="subject"
                        placeholder="Subject"
                      />
                      {errors.subject && (
                        <span className="text-danger d-block error">
                          {errors.subject.message}
                        </span>
                      )}
                    </div>
                    <div class="form-group col-12">
                      <textarea
                        {...contactInput("message", {
                          required: "Please Enter Message",
                        })}
                        class="form-control"
                        style={{ height: "280px" }}
                        id="message"
                        placeholder="Type Your Message..."
                      ></textarea>
                      {errors.message && (
                        <span className="text-danger d-block error">
                          {errors.message.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div class="form-btn my-3">
                    <button class="btn-grad">Send Message</button>
                  </div>
                  <p class="form-messages"></p>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
