import React, { useState, useEffect } from 'react'
import http from '../../CommonAxios'
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const Team = () => {
    const [apiData, setApiData] = useState({});
    const [loader, setLoader] = useState(false);

    const onApiInteg = () => {
        let data = { admin_id: 1 }
        setLoader(true);
        http.post(`/speaker/front/list`, data).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {
                setApiData(res.data)
            }
        }).catch(function (err) {
            setLoader(false);
        });
    };
    useEffect(() => {
        onApiInteg();
    }, []);
    const PreviousArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", background: "black", borderRadius: "50%" }}
                onClick={onClick}
            >
                <FontAwesomeIcon icon={faChevronLeft} style={{ color: "#f0411b", fontSize: "2rem", padding: "10px" }} />
            </div>
        );
    };
    
     const NextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", background: "black", borderRadius: "50%" }}
                onClick={onClick}
            >
                <FontAwesomeIcon icon={faChevronRight} style={{ color: "#f0411b", fontSize: "2rem", padding: "10px" }} />
            </div>
        );
    };
    const settings = {
        dots: true,
        arrows: true,
        infinite: true,
        prevArrow: <PreviousArrow />,
        nextArrow: <NextArrow />,
        speed: 500,
        // slidesToShow: 4,
        slidesToShow: checkData(apiData) && checkData(apiData.data) && Object.keys(apiData.data).length > 2 ? 3 : checkData(apiData) && checkData(apiData.data) ? Object.keys(apiData.data).length : '',
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: checkData(apiData) && checkData(apiData.data) && Object.keys(apiData.data).length > 1 ? 2 : checkData(apiData) && checkData(apiData.data) ? Object.keys(apiData.data).length : '',
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <section class="space-shape mt-n75 arrow-wrap shape-mockup-wrap teamall">
            <div class="shape-bg background-image"
            // style={{ backgroundImage: 'url(./img/team_bg_1.png)' }}
            ></div>
            <div class="container z-index-common">
                <div class="title-area2 text-center">
                    <span class="sub-title2">
                        Event Speakers
                        <img class="title-shape" src="./img/title_shape.png" alt="title shape" />
                    </span>
                    <h2 class="sec-title-ndm">Meet Our Speakers</h2>
                </div>
                <Slider {...settings} className="12">
                    {checkData(apiData) && checkData(apiData) ? apiData.data.map((item, index) => (
                        <div class="team-grid">
                            <div class="team-grid_img">
                                {checkUndeNullBlank(item.image) ?
                                    <img   style={{"width":"75%"}}   src={`${apiData.path}/${item.image}`} alt="team image" /> :
                                    <img src="./img/team_1_1.jpg" alt="team image" />
                                }
                                {/* <div class="plus-icon">+</div> */}
                                <div class="team-social">
                                    {item.long_desc}
                                </div>
                            </div>
                            <div class="team-grid_info">
                                <h3 class="team-grid_title">
                                    {item.title}
                                </h3>
                                <span class="team-grid_desig">{item.designation}</span>
                            </div>
                        </div>
                    )) : ''}
                </Slider>
                <div class="row slider-shadow vs-carousel slick-initialized slick-slider" data-slide-show="4" data-lg-slide-show="3" data-sm-slide-show="2" data-arrows="true">


                    <div class="slick-list draggable"><div class="slick-track" style={{ opacity: 1, width: '4382px', transform: 'translate3d(-2191px, 0px, 0px)', transition: 'transform 1000ms ease 0s' }}><div class="col-lg-4 col-sm-6 slick-slide slick-cloned" style={{ width: '313px' }} tabindex="-1" data-slick-index="-4" id="" aria-hidden="true">

                    </div></div></div></div>
            </div>
            <div class="shape-mockup jump z-index-3 d-none d-xl-block" style={{ top: '34%', left: '3%' }}><img src="./img/circle-3.png" alt="shapes" /></div>
            <div class="shape-mockup jump-reverse z-index-3 d-none d-xl-block" style={{ top: '14%', right: '0%' }}><img src="./img/circle-4.png" alt="shapes" /></div>
            <div class="section-after">
                <img src="./img/curved-shape-bottom.png" alt="shape" />
            </div>
        </section>
    )
}
export default Team
