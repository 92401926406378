import React, { useState, useEffect } from 'react'
import { Container, Row, Card, CardHeader, CardTitle, CardBody, Table, Spinner, Form, FormGroup, Button, Col, Badge, Label, Modal, ModalHeader, ModalBody, Pagination, PaginationItem, PaginationLink, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, } from 'reactstrap'
import { ArrowDown, ArrowUp, ChevronLeft, ChevronRight, ChevronsRight } from 'react-feather';
import http from '../../CommonAxios'
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { useForm } from 'react-hook-form';
import { changeIntoDatedmY } from '../../utlis';
import Swal from 'sweetalert2';
import { nonMemberCategory,Registertype,Usertype } from '../../Config';
import { Link } from 'react-router-dom';
import PermissionNotAllowed from './PermissionNotAllowed';
import { event } from 'react-ga';
 


const RegistartionList = (props) => {
    const [loader, setLoader] = useState(false);
    const [apiData, setApiData] = useState({});
    const [viewModal, setViewModal] = useState(false);
    const [viewData, setViewData] = useState({});
    const [page, setPage] = useState(1);
    const [selectperpage, setSelectperpage] = useState(20);
    const [currentCount, setCurrentCount] = useState(1);
    const [filterField, setFilterField] = useState({});
    const [excelFile, setExcelFile] = useState();
    const [excelmodal, setExcelmodal] = useState(false);
    
    const [selectedID, setselectedID] = useState();
    var [selectedUserTypeID, setselectedUserTypeID] = useState();
    var [selectedInvoiceNo, setselectedInvoiceNo] = useState();
    var [selectedInvoiceDate, setselectedInvoiceDate] = useState();
 
    const [modalDownload, setModalDownload] = useState(false);

   const [inputAWBNo, setInputAWBNo] = useState('');

    const dropFilterCount = 20;
    const handleFileInput = (e) => {
        setExcelFile(e.target.files[0])
    }
     
      const toggleDownload = () => {
        setModalDownload(!modalDownload)
        setInputAWBNo('');
      };

      const seperateAWBno = (e) => {
        var text = e.target.value;
        var lastData = text.slice(-1);
        var inputData = text;
        var newText = "";
        var listData = "";
        listData = text.replace(/\n|\r/g, "");
        listData = inputData.split(/,+/);
        setInputAWBNo(text);
        if (lastData === ",") {
          newText = listData.toString();
          setInputAWBNo(newText);
        }
    
        if (listData.length > 200) {
          Swal.fire({ icon: 'error', text: "Maximum 200 Register ID  Allowed", title: 'Error' });
        }
      }
      const { register: bulkLabel, handleSubmit: bulkLabelDownload, formState: { errors: errormanlabel } } = useForm();

      const labelDownload = (data) => {
        // setLoader(true);
        var lastData = data.register_ids.slice(-1);
    
        data.register_ids = data.register_ids.split(',')
        // labelDownloadCommon(data);
        if (lastData === ",") {
          data.register_ids.pop();
        }
        if (data.register_ids.length <= 200) {
          labelDownloadCommon(data);
        } else {
          Swal.fire({ icon: 'error', text: "Maximum 200 Register ID  Allowed", title: 'Error' });
        }
    
      }

      const labelDownloadCommon = (data) => {
        setLoader(true);
        http.post(`event/registration/generate-id-card`, data).then((res) => {

     
            setLoader(false);

          if (res.data.s === 1 && res.data.file!==undefined && res.data.file!=="") {
            let downloadFile = '';
            if (res.data.file !== undefined) {
                downloadFile = checkUndeNullBlank(res.data.file) ? process.env.REACT_APP_BASE_URL + '/api' + res.data.file : '';
            }
            Swal.fire({
                title: 'Success',
                html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">ID Cards</th><td style="padding: 0.25rem 0;"><a href=${downloadFile} >Download ID Card PDF</a></td></tr></tbody></Table>`,
                text: res.data.msg,
                icon: 'success',
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then(okay => {
                if (okay) {
                    onApiInteg();
                }
            });

          }else {
            Swal.fire({ title: 'Error', text: res.msg, icon: 'error', });
          }

        }).catch((err) => {
          alert("Error In genreating "+err.stack);
            //errorHandling(err, "")
          setLoader(false);
        });
      }

      const handleChangeUserType = (event) => {

        try{
        const value = event.target.value; // Get the input value
        const id = event.target.id; // Get the input id

        console.log('value',value,'id',id);

       // const { value } = event.target;
        // Update selectedId when the select value changes
        if(id==="user_type"){
            setselectedUserTypeID(value);
        }
        if(id==="invoice_no"){
            setselectedInvoiceNo(value);
        }
        if(id === "invoice_date"){
            setselectedInvoiceDate(value);
        }

        //console.log('selectedUserTypeID',selectedUserTypeID,'selectedInvoiceNo',selectedInvoiceNo,'selectedInvoiceDate',selectedInvoiceDate);

        if(value!==""){
            ChangeUserType(value);
        }else{
            Swal.fire({
                title: 'Error',
                text: "Put invoice_no,invoice_date or user type",
                icon: 'error',
            });
        }
    }catch(err){
        console.error("erro..",err.message);
    }
         
      };


      function ChangeUserType() {
        
        setLoader(true);
        var data={'id':selectedID,'invoice_no':selectedInvoiceNo,invoice_date:selectedInvoiceDate,'user_type_id':selectedUserTypeID};
        http.post(`event/registration/update-user-id`, data).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {
                Swal.fire({
                    title: 'Success',
                    text: 'User ID Updated',
                    icon: 'success',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then(okay => {
                    if (okay) {
                        onApiInteg();
                    }
                });
            }else{
                Swal.fire({
                    title: 'Error',
                    text: res.data.msg,
                    icon: 'error',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then(okay => {
                    if (okay) {
                        onApiInteg();
                    }
                });
            }
        }).catch(function (err) {
            setLoader(false);
        });

      }

    const toggleView = (id) => {
        setViewModal(!viewModal)
        let data = { admin_id: checkData(props.user) ? props.user.id : '', id: id }
        setselectedID(id);

        setLoader(true);
        http.post(`event/registration/view`, data).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {
                setViewData(res.data.data[0]);
            }
        }).catch(function (err) {
            setLoader(false);
        });
    }
    const getUsernameFromId=(id)=>{
      //  console.log("")
       let  USER_TYPES=Usertype;
       if(Number(id)>0){
        let type=(USER_TYPES[id]!==undefined)?USER_TYPES[id]:"";
        console.log("type",type);
        return type;
       }else{
        return 'NA';
       }


    }
    const gInvoice = (id) => {
        let data = { id: id }
        setLoader(true);
        http.post(`event/registration/generate-invoice`, data).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {
                Swal.fire({
                    title: 'Success',
                    text: 'Invoice generated successfully',
                    icon: 'success',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then(okay => {
                    if (okay) {
                        onApiInteg();
                    }
                });
            } else {
                Swal.fire({
                    title: 'Error',
                    text: res.data.msg,
                    icon: 'error',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then(okay => {
                    if (okay) {
                        onApiInteg();
                    }
                });
            }
        }).catch(function (err) {
            setLoader(false);
        });
    }
    const sendInvoice = (id) => {
        let data = { id: id }
        setLoader(true);
        http.post(`event/registration/invoice-send-email`, data).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {
                Swal.fire({
                    title: 'Success',
                    text: 'Invoice email sent successfully',
                    icon: 'success',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then(okay => {
                    if (okay) {
                        onApiInteg();
                    }
                });
            } else {
                Swal.fire({
                    title: 'Error',
                    text: res.data.msg,
                    icon: 'error',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then(okay => {
                    if (okay) {
                        onApiInteg();
                    }
                });
            }
        }).catch(function (err) {
            setLoader(false);
        });
    }
    const { register: uploadRegistrationdata, handleSubmit: orderRegistrationSubmit, formState: { errors: errors3 }, reset: formAdd } = useForm();

    const excelUploadList = () => {
        setExcelmodal(!excelmodal);
        formAdd();
        if (!excelmodal) { setExcelFile(""); }
    }
     

    const onApiInteg = () => {
        setFilterField({});
        let data = { admin_id: 1 }
        setLoader(true);
        http.post(`event/registration/list`, data).then((res) => {
            setLoader(false);

            if (res.data.s === 1) {
                setApiData(res.data)
            }
        }).catch(function (err) {
            setLoader(false);
        });
    };
    useEffect(() => {
        onApiInteg();
    }, [])
    const onUploadRegistrationSubmit = (data) => {
        setLoader(true);
        let formData = new FormData();
        formData.append("file", excelFile);
        formData.append("admin_id", checkData(props.user) ? props.user.id : '');
        http.post(`event/registration/upload`, formData).then((res) => {
            setLoader(false);
            let errorFile = '';
            formAdd();
            setExcelmodal(false); setExcelFile('');
            if (res.data.errorfile !== undefined) {
                errorFile = checkUndeNullBlank(res.data.errorfile) ? process.env.REACT_APP_BASE_URL + '/api' + res.data.errorfile : '';
            }
            if (res.data.error === false) {
                Swal.fire({
                    title: 'Success',
                    html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Uploaded</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td>${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' ? `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>` : ''}</tbody></Table>`,
                    text: res.data.msg,
                    icon: 'success',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then(okay => {
                    if (okay) {
                        onApiInteg();
                    }
                });
            } else {
                Swal.fire({
                    title: 'Error',
                    text: res.data.msg,
                    html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Uploaded</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td style="padding: 0.25rem 0;">${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' && `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>`}</tbody></Table>`,
                    icon: 'error',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then(okay => {
                    if (okay) {
                        onApiInteg();
                    }
                });
            }
            // if (res.data.s === 1) {
            //     Swal.fire({ title: 'Success', text: res.data.msg, icon: 'success', });
            //     formAdd(); onApiInteg();
            // } else {
            //     Swal.fire({ icon: 'error', title: 'Error', text: res.data.message, });
            // }
        }).catch(function (err) {
            setLoader(false);
        });

    };
    const { register: filterInput, handleSubmit: filterSubmit, formState: { errors }, reset } = useForm();
    const onFilter = (data, pagePerpage) => {
        pagePerpage.page = 1
        // if((checkUndeNullBlank(data.search_field) && checkUndeNullBlank(data.search_value))  || checkData(orderStatus)|| checkData(data.movement_type_id) || checkData(allCourier) || checkData(merchants) || checkData(data.delivery_type_id) || (checkData(filterDate.length) && filterDate.length > 0) || (checkData(eventDate.length) && eventDate.length > 0)){
        if (checkUndeNullBlank(data.pay_status) || checkUndeNullBlank(data.phone) || checkUndeNullBlank(data.email) || checkUndeNullBlank(data.member_type_id) || checkUndeNullBlank(data.ibbi_no) || checkUndeNullBlank(data.member_type_id) || checkUndeNullBlank(data.iov_number)) {
            setCurrentCount(1);
            setSelectperpage(selectperpage);
            setFilterField(data);
            onCommonFilter(data, pagePerpage)
        } else {
            Swal.fire({ title: 'info', text: 'Please Choose Filter', icon: 'info', })
        }
    };
    const formReset = () => {
        reset();
        onApiInteg();
    }



    function previousPageData() {
        if (page > 1) {
            setPage(page - 1)
            checkData(apiData.data)
            if (currentCount > Object.entries(apiData.data).length) {
                setCurrentCount(currentCount - selectperpage)
            } else {
                setCurrentCount(1)
            }
            let pagePerpage = { "page": page > 1 && page - 1, "per_page": selectperpage };
            onpaginationFilter(filterField, pagePerpage);
        }
    }

    function handleSelectChange(event) {
        setSelectperpage(event.target.value);
        // setLoadertable(true)
        setPage(1)
        setCurrentCount(1)
        let pagePerpage = { "page": 1, "per_page": event.target.value };
        onpaginationFilter(filterField, pagePerpage)
    }
    function nextPageData() {
        if (checkData(apiData.data) && checkUndeNullBlank(apiData.totalPage)) {
            // &&checkUndeNullBlank(apiData.totalPage).length>page.length
            if (apiData.totalPage > page) {
                setCurrentCount(currentCount + Object.entries(apiData.data).length)
                setPage(page + 1)
                // setLoadertable(true)
                let pagePerpage = { "page": page + 1, "per_page": selectperpage };
                onpaginationFilter(filterField, pagePerpage);
            }
        }
    }


    const onpaginationFilter = (data, pagePerpage) => {
        onCommonFilter(data, pagePerpage)
    };

    const onCommonFilter = (data, pagePerpage) => {
        let pageNumber = checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page
        let perPageCount = checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage

        let byData = { pay_status: data.pay_status, phone: data.phone, email: data.email, member_type_id: data.member_type_id, ibbi_no: data.ibbi_no, iov_number: data.iov_number, page: pageNumber, per_page: perPageCount }
        byData.admin_id = checkData(props.user) ? props.user.id : ''
        setLoader(true);
        http.post(`event/registration/list`, byData).then((res) => {
            setLoader(false);
            //if (res.data.s === 1) {
            setApiData(res.data)
            //}
        }).catch(function (err) {
            setLoader(false);
        });
    };
    const exportShipment = () => {
        // if (checkData(filterField)) {
        let data = filterField
        data.admin_id = checkData(props.user) ? props.user.id : ''
        data.is_export = 1
        setLoader(true);
        http.post(`event/registration/list`, data).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {
                window.location.href = res.data.file;
            }
        }).catch(function (err) {
            setLoader(false);
        });

        // } else {
        //     Swal.fire({ icon: 'error', title: 'Error', text: 'Please Choose Filter' })
        // }
    }
    return (
        <div className="app-body overflow-hidden">
            {loader && <div className="formLoader"><Spinner /></div>}

            <Modal isOpen={modalDownload} toggle={toggleDownload} size="lg" scrollable={true}>
                <ModalHeader toggle={toggleDownload}>ID Card Generation Module</ModalHeader>
                <ModalBody>
                  <Form onSubmit={bulkLabelDownload(labelDownload)}>
                    <Row className="mx-0 justify-content-center downloadModal">
                      <Col sm={12}>
                        <FormGroup>
                          <Label for="exampleText">Please Enter Comma Seperated Register ID like (GVS-10001,GVS-10002,GVS-10003)</Label>
                          <textarea {...bulkLabel("register_ids", { required: 'Please Enter Comma Seperated Register ID like (GVS-10001)' })} style={{"height":"270px"}} value={inputAWBNo} onChange={e => seperateAWBno(e)} rows="8" className="form-control-sm form-control" placeholder="Please Enter Comma Seperated Register ID" id="labels" />
                          {/* <Input type="textarea" name="text" id="exampleText" rows="15" /> */}
                          {/* <span className="small" > 500 Docket Numbers (approx) or 5000 Characters Allowed</span> */}
                          {errormanlabel.register_ids && <span className="text-danger d-block error">{errormanlabel.register_ids.message}</span>}
                          <span className="small" > Maximum 200 Register ID Allowed</span>
                        </FormGroup>
                      </Col>
                      
                      <Col sm={12} className="text-right">
                        <Button className="btn ctm-btn btn-sm" color="primary">Generate ID Card(s)</Button>
                      </Col>
                    </Row>
                  </Form>
                </ModalBody>
              </Modal>

            <Modal isOpen={excelmodal} toggle={excelmodal} scrollable={true} >
                <ModalHeader toggle={excelUploadList}>Upload List</ModalHeader>
                <ModalBody>
                    <Form onSubmit={orderRegistrationSubmit(onUploadRegistrationSubmit)}>
                        <p className="mb-2"><span className="me-2"><ChevronsRight /> </span>Upload all Your List via XLSX</p>
                        <p className="mb-2"><span className="me-2"><ChevronsRight /> </span>Please download below given template</p>
                        <p className="mb-2"><span className="me-2"><ChevronsRight /> </span>Remove sample data and upload your data</p>
                        <p className="mb-2"><span className="me-2"><ChevronsRight /> </span>Make sure you atleast fill mandatory fields.</p>

                        <Row className="m-0 pb-1">
                            <Col sm={12} className="p-0">
                                <p className="mb-2"><span className="me-2"><ChevronsRight /></span> <Link target="_blank" download to="/file/Registration_file.xlsx" className="">  Download
                                </Link>
                                    <span className="ms-2">Registration Format File</span></p>
                            </Col>
                            <Col sm={12} className="m-auto pt-1 p-0">
                                <FormGroup className="position-relative">
                                    <input {...uploadRegistrationdata("excelUpload", {
                                        required: 'Please Upload XLSX File',
                                        validate: {
                                            lessThan5MB: files => files[0]?.size < 5242880 || 'Max 5MB',
                                            acceptedFormats: files =>
                                                ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(
                                                    files[0]?.type
                                                ) || 'Only .xslx file allowed',
                                        },
                                    })} type="file" id="excelUpload" accept=".xlsx" className="custom-file-input" onChange={handleFileInput} />
                                    <Label className="custom-file-label" for="inputGroupFile01">{excelFile !== '' && excelFile !== undefined ? excelFile.name : 'Choose file'}</Label>
                                    {errors3.excelUpload && <span className="text-danger d-block error">{errors3.excelUpload.message}</span>}
                                </FormGroup>
                            </Col>
                            <Col sm={12} className="text-right">
                                <Button className="btn ctm-btn btn-sm" color="primary">Upload</Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>

            {checkData(viewData) &&
                <Modal isOpen={viewModal} toggle={viewModal} scrollable={true} size="lg" className='addModal'>
                    <ModalHeader toggle={toggleView}>Registered Member  { viewData.register_id }</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row className="mx-0  addModal">
                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>Name</Label>
                                        <div>
                                            {viewData.first_name} {viewData.last_name} <br></br>
                                            <small><strong> { getUsernameFromId(viewData.user_type_id) }</strong></small>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>Email</Label>
                                        <div>
                                            {viewData.email || 'NA'}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>Phone</Label>
                                        <div>
                                            {viewData.phone || 'NA'}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>Member Type ID</Label>
                                        <div>
                                            {parseInt(viewData.member_type_id) === 1 ? 'Member' : 'Non-Member'}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>Non-Member Category</Label>
                                        <div>
                                            {checkUndeNullBlank(viewData.non_member_category_id) ?
                                                <>
                                                    {nonMemberCategory[viewData.non_member_category_id]}
                                                </> :
                                                'NA'
                                            }
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>IOV Number</Label>
                                        <div>
                                            {viewData.iov_number || 'NA'}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>IBBI Number</Label>
                                        <div>
                                            {viewData.ibbi_no || 'NA'}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>Order ID</Label>
                                        <div>
                                            {viewData.order_id || 'NA'}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>Register ID</Label>
                                        <div>
                                            {viewData.id || 'NA'}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>Payment ID</Label>
                                        <div>
                                            {viewData.payment_id || 'NA'}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>Total Amount</Label>
                                        <div>
                                            {viewData.total_amt || 'NA'}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>Pay Status</Label>
                                        <div>
                                            {checkUndeNullBlank(viewData.pay_status) && viewData.pay_status ? <Badge color="success">Paid</Badge> : <Badge color="danger">UnPaid</Badge>}
                                        </div>
                                        <br></br>
                                        <Label>Invoice No.</Label>
                                        <div>
                                            {checkUndeNullBlank(viewData.invoice_no) && viewData.invoice_no ? viewData.invoice_no : "NA"}
                                        </div>
                                        <br></br>
                                        <Label>Invoice Date</Label>
                                        <div>
                                            {checkUndeNullBlank(viewData.invoice_date) && viewData.invoice_date ? viewData.invoice_date : "NA"}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>Pay Date</Label>
                                        <div>
                                            {viewData.pay_date || 'NA'}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>Pay Remarks</Label>
                                        <div>
                                            {viewData.remarks || 'NA'}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>City</Label>
                                        <div>
                                            {viewData.city || 'NA'}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>State</Label>
                                        <div>
                                            {viewData.state || 'NA'}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>Country</Label>
                                        <div>
                                            {viewData.country || 'NA'}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>Pincode</Label>
                                        <div>
                                            {viewData.pincode || 'NA'}
                                        </div>
                                    </FormGroup>
                                </Col>
                                {checkUndeNullBlank(viewData.gst_no) &&
                                    <Col sm={6} xl={4}>
                                        <FormGroup>
                                            <Label>GST No.</Label>
                                            <div>
                                                {viewData.gst_no || 'NA'}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                }
                                {checkUndeNullBlank(viewData.passport_no) &&
                                    <Col sm={6} xl={4}>
                                        <FormGroup>
                                            <Label>Passport No.</Label>
                                            <div>
                                                {viewData.passport_no || 'NA'}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                }
                                {checkUndeNullBlank(viewData.business_visa_no) &&
                                    <Col sm={6} xl={4}>
                                        <FormGroup>
                                            <Label>Visa No.</Label>
                                            <div>
                                                {viewData.business_visa_no || 'NA'}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                }
                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>Created At</Label>
                                        <div>
                                            {changeIntoDatedmY(viewData.created_at)}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>Updated At</Label>
                                        <div>
                                            {changeIntoDatedmY(viewData.updated_at)}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup className="position-relative p-0">
                                         <Label for="day">Change/Update User Type<span className="text-danger">*</span></Label>
                                        <select id="user_type" onChange={handleChangeUserType} className="form-control-sm form-control">
                                        <option value="">Select User Types</option>
                                        {Object.keys(Usertype).map(key=>
                                            <option value={ key }>{ Usertype[key] }</option>  
                                        )}                                           
                                        </select>
                                      
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                <FormGroup>
                                        <Label>Enter Invoice NO</Label>
                                        <div>
                                           <input type='text' onBlur={handleChangeUserType} className='form-control' id='invoice_no' name="invoice_no" ></input>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                <FormGroup>
                                        <Label>Enter Invoice Date  </Label>
                                        <div>
                                           <input type='date'  onChange={handleChangeUserType} className='form-control' id='invoice_date' name="invoice_date"></input>
                                        </div>
                                    </FormGroup>
                                </Col>
                                {checkUndeNullBlank(viewData.delegate_name_2) &&
                                    <Col sm={6} xl={4}>
                                        <FormGroup>
                                            <Label>Delegate One Name</Label>
                                            <div>
                                                {viewData.delegate_name_2 || 'NA'}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                }
                                {checkUndeNullBlank(viewData.delegate_age_1) &&
                                    <Col sm={6} xl={4}>
                                        <FormGroup>
                                            <Label>Delegate One Age</Label>
                                            <div>
                                                {viewData.delegate_age_1 || 'NA'}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                }
                                {checkUndeNullBlank(viewData.delegate_gender_1) &&
                                    <Col sm={6} xl={4}>
                                        <FormGroup>
                                            <Label>Delegate One Gender</Label>
                                            <div>
                                                {viewData.delegate_gender_1 || 'NA'}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                }
                                {checkUndeNullBlank(viewData.delegate_name_2) &&
                                    <Col sm={6} xl={4}>
                                        <FormGroup>
                                            <Label>Delegate Two Name</Label>
                                            <div>
                                                {viewData.delegate_name_2 || 'NA'}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                }
                                {checkUndeNullBlank(viewData.delegate_age_2) &&
                                    <Col sm={6} xl={4}>
                                        <FormGroup>
                                            <Label>Delegate Two Age</Label>
                                            <div>
                                                {viewData.delegate_age_2 || 'NA'}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                }
                                {checkUndeNullBlank(viewData.delegate_gender_2) &&
                                    <Col sm={6} xl={4}>
                                        <FormGroup>
                                            <Label>Delegate Two Gender</Label>
                                            <div>
                                                {viewData.delegate_gender_2 || 'NA' || 'NA'}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                }
                            </Row>
                        </Form>
                    </ModalBody>
                </Modal>
            }
            <Row>
                <Col sm={12}>
                    <Card className="p-0 manageOrder shadow content-wrapper border-0">
                        <Card className="border-0">
                            <CardHeader className="page-header d-flex justify-content-between flex-wrap">
                                <CardTitle className="page-title">Manage Registration List</CardTitle>
                                <div className="">
                                    {checkData(props.permissions) && props.permissions.Registration.export &&
                                        <span onClick={exportShipment} className="cursor-pointer me-2 btn btn-primary">
                                            <span className="icon ms-2" ><ArrowDown /> </span><span>Download</span>
                                        </span>
                                    }
                                    {checkData(props.permissions) && props.permissions.Registration.import &&
                                        <span className="cursor-pointer ms-2 btn btn-primary" onClick={excelUploadList}>
                                            <span className="icon ms-2 cursor-pointer"><ArrowUp /> </span><span>Upload</span>
                                        </span>
                                    }
                                    {checkData(props.permissions) && props.permissions.Registration.export &&
                                        <span className="cursor-pointer ms-2 btn btn-primary" onClick={toggleDownload}>
                                            <span className="icon ms-2 cursor-pointer"><ArrowUp /> </span><span>ID Card Generate</span>
                                        </span>
                                    }
                                </div>
                            </CardHeader>
                            <Form onSubmit={filterSubmit(onFilter)} className="cardFilter pb-3">
                                <Row className="mx-0 justify-content-start justify-content-lg-center">
                                    <Col sm={6} md={6} lg={4} xl={3}>
                                        <FormGroup>
                                            <select {...filterInput("pay_status")} className="form-control" id="payStatus">
                                                <option value="">Select Pay Status</option>
                                                <option value="1">Paid</option>
                                                <option value="0">UnPaid</option>
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6} md={6} lg={4} xl={3}>
                                        <FormGroup>
                                            <select {...filterInput("member_type_id")} className="form-control" id="payStatus">
                                                <option value="">Select Member</option>
                                                <option value="1">Member</option>
                                                <option value="2">Non Member</option>
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6} md={6} lg={4} xl={3}>
                                        <FormGroup>
                                            <input {...filterInput("ibbi_no")} className="form-control" id="registno" placeholder="IBBI Registration No." />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6} md={6} lg={4} xl={3}>
                                        <FormGroup>
                                            <input {...filterInput("iov_number")} className="form-control" id="membershipno" placeholder="IOV Membership No." />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6} md={6} lg={4} xl={3}>
                                        <FormGroup>
                                            <input {...filterInput("phone")} className="form-control" id="phone" placeholder="Phone No." />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6} md={6} lg={4} xl={3}>
                                        <FormGroup>
                                            <input {...filterInput("email")} className="form-control" id="email" placeholder="Email ID" />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3} className="btn-container">
                                        <Button className="btn ctm-btn btn-sm" color="primary">Search</Button>
                                        <Button type="reset" className="btn ctm-btn btn-sm mx-2" color="primary" outline onClick={formReset}>Reset</Button>
                                    </Col>
                                </Row>
                            </Form>
                            {(checkData(props.permissions) && props.permissions.Registration.list) ?
                                <CardBody className="p-0">
                                    <div className="showing-count px-3 py-2">
                                        {checkData(apiData) && checkData(apiData.data) && checkUndeNullBlank(apiData.TotalRows) ?
                                            <span className="small">Showing {currentCount} to {(currentCount - 1) + (Object.entries(apiData.data).length)} of {apiData.TotalRows}</span> : ''}
                                    </div>
                                    <Table responsive className="text-center custom-table">
                                        <thead>
                                            <tr>
                                                <th className="text-nowrap">Member Detail</th>
                                                <th className="text-nowrap">Member Categry</th>
                                                <th className="text-nowrap">Transaction Detail</th>
                                                <th className="text-nowrap">pay status</th>
                                                <th className="text-nowrap">Date</th>
                                                <th className="text-nowrap">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {checkData(apiData) && checkData(apiData.data) ? apiData.data.map((item, index) => (
                                                <tr>
                                                    <td className="align-middle">
                                                        <span className='font-bold-7'>Name : </span> {item.first_name} {item.last_name}<br />
                                                        <span className='font-bold-7'>Phone : </span> {item.phone || 'NA'} <br />
                                                        <span className='font-bold-7'>Email : </span> {item.email || 'NA'} <br/>
                                                        {/* <span className='font-bold-7'>Register Type : </span> {Registertype[item.registration_type_id] || 'NA'} <br/> */}
                                                    </td>
                                                    <td className="align-middle">
                                                        <span className='font-bold-7'>Member Type : </span> {parseInt(item.member_type_id) === 1 ? 'Member' : 'Non-Member'}<br />
                                                        {/* {checkUndeNullBlank(item.non_member_category_id) ?
                                                            <>
                                                                <span className='font-bold-7'>Non-Member Category : </span> {nonMemberCategory[item.non_member_category_id]} <br />
                                                            </> :
                                                            <>
                                                                <span className='font-bold-7'>Non-Member Category : </span> 'NA' <br />
                                                            </>
                                                        } */}
                                                        <span className='font-bold-7'>IOV Number : </span> {item.iov_number || 'NA'}<br />
                                                        <span className='font-bold-7'>IBBI Number : </span> {item.ibbi_no || 'NA'} <br/>
                                                        <span className='font-bold-7'>Residential Type : </span> {item.residential_type || 'NA'}
                                                    </td>
                                                    <td className="align-middle">
                                                        <span className='font-bold-7'>Order ID : </span> {item.order_id || 'NA'}<br />
                                                        <span className='font-bold-7'>Register ID : </span> {item.register_id || 'NA'} <br />
                                                        <span className='font-bold-7'>Payment ID : </span> {item.payment_id || 'NA'} <br />
                                                        <span className='font-bold-7'>Total Amount : </span> {item.total_amt || 'NA'}
                                                    </td>
                                                    <td className="align-middle text-nowrap">
                                                        <span className='font-bold-7'>Status : </span> {checkUndeNullBlank(item.pay_status) && item.pay_status ? <Badge color="success">Paid</Badge> : <Badge color="danger">UnPaid</Badge>}<br />
                                                        <span className='font-bold-7'>Invoice No. : </span> {item.invoice_no || 'NA'}  <br></br>
                                                        <span className='font-bold-7'>Invoice Date. : </span> {item.invoice_date || 'NA'} 

                                                    </td>
                                                    <td className="align-middle text-nowrap">
                                                        <span className='font-bold-7'>Pay At : </span> {changeIntoDatedmY(item.pay_date)} <br />
                                                        <span className='font-bold-7'>Created At : </span> {changeIntoDatedmY(item.created_at)} <br />
                                                        <span className='font-bold-7'>Updated At : </span> {changeIntoDatedmY(item.updated_at)}
                                                    </td>
                                                    <td>
                                                        <UncontrolledDropdown setActiveFromChild >
                                                            <DropdownToggle caret className="py-1 px-2 d-flex align-items-center bg-transparent color-black">
                                                                <div className="avatar text-black">More &darr;</div>
                                                            </DropdownToggle>
                                                            <DropdownMenu className="mt-2">
                                                                <NavItem className="dropdown-item cursor-pointer" onClick={() => toggleView(item.id)}><span className="mr-2">
                                                                </span>View</NavItem>
                                                                <NavItem className="dropdown-item cursor-pointer" onClick={() => gInvoice(item.id)}><span className="mr-2">
                                                                </span>Generate invoice</NavItem>
                                                                {checkUndeNullBlank(item.invoice_file) &&
                                                                    <NavItem className="dropdown-item cursor-pointer"><Link target="_blank" download to={`https://iovivc.com/api/download/${item.invoice_file}`} className="dropdown-item p-0 text-decoration-none"><span className="mr-2">
                                                                    </span>Download Invoice</Link></NavItem>
                                                                }
                                                                <NavItem className="dropdown-item cursor-pointer" onClick={() => sendInvoice(item.id)}><span className="mr-2">
                                                                </span>Send invoice to customer</NavItem>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </td>
                                                </tr>
                                            )) : <tr><td colSpan={6}><center className="text-danger">No Records Found</center></td></tr>}
                                        </tbody>
                                    </Table>
                                    {checkData(apiData) && checkData(apiData.data) && checkUndeNullBlank(apiData.TotalRows) ?
                                        <>
                                            {apiData.TotalRows > dropFilterCount ?
                                                <Row className="m-0 table-footer">
                                                    <Col xs="6">
                                                        <Pagination aria-label="Page navigation example align-items-center">
                                                            <PaginationItem className="prev">
                                                                <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center me-3"><ChevronLeft /></PaginationLink>
                                                            </PaginationItem>
                                                            <PaginationItem className="next ml-3">
                                                                <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                                                            </PaginationItem>
                                                        </Pagination>
                                                    </Col>
                                                    <Col xs="6">
                                                        <Form>
                                                            <FormGroup>
                                                                {/* <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span> */}
                                                                <select type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm form-control w-auto ms-auto pe-4">
                                                                    <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                                                                    <option value="20">20</option>
                                                                    <option value="50">50</option>
                                                                    <option value="250">250</option>
                                                                </select>
                                                            </FormGroup>
                                                        </Form>
                                                    </Col>
                                                </Row> : ''}
                                        </>
                                        : ''}
                                </CardBody>
                                :
                                <PermissionNotAllowed />}
                        </Card>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default RegistartionList