import axios from "axios";


export default axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api/v1`,
  headers: {
    "Content-type": "application/json",
    "token":window.btoa(process.env.REACT_APP_STRING)
  }
});
