import React, { useState } from 'react';
import { Nav, NavItem, CardImg, Collapse } from 'reactstrap';
import { Link } from 'react-router-dom';
// import LogoL from './img/logo.png';
import { MessageSquare, Bell, BellOff, Home, Truck, Users, ShoppingCart, FileText, Package, RotateCw, FileMinus, Tool, List, User, Mail, CreditCard, ArrowRight, Book, Layers, UserCheck, UserPlus } from 'react-feather';
import { useLocation } from 'react-router';

const Sidebar = (props) => {
    const [expanded, setExpanded] = useState(false);
    const urlPath = useLocation();



    return (
        <div className={`side-menu menu-fixed menu-accordion menu-shadow menu-light ${!expanded ? "expanded" : ""}`} onMouseEnter={() => setExpanded(true)} onMouseLeave={() => setExpanded(false)}>
            <div className="navbar-header position-relative d-flex align-items-center justify-content-center">
                <Nav vertical>
                    <NavItem>
                        <Link to="/admin" className="mx-auto d-flex align-items-center justify-content-center navbar-brand">
                            <CardImg top src='./img/logo.png' alt="Card image cap mx-auto" style={{ width: "120px" }} className="brand-logo" />
                        </Link>
                    </NavItem>
                </Nav>
            </div>
            <div className="scrollbar-container main-menu-content">
                <Nav vertical className="navigation navigation-main">
                    <NavItem className={`${urlPath.pathname === '/dashboard' ? 'active' : ''}`}>
                        <Link to='/dashboard' className="d-flex align-items-center nav-link font-weight-500">
                            <span className="me-2 menu-icon"><Home /></span>
                            <span className="menu-title">Dashboard</span>
                        </Link>
                    </NavItem>
                    <NavItem className={`${urlPath.pathname === '/event' ? 'active' : ''}`}>
                        <Link to='/event' className="d-flex align-items-center nav-link font-weight-500">
                            <span className="me-2 menu-icon"><MessageSquare /></span>
                            <span className="menu-title">Events</span>
                        </Link>
                    </NavItem>
                    {/* <NavItem className=''>
                    <Link to='/gallery' className="d-flex align-items-center nav-link font-weight-500">
                    <span className="me-2 menu-icon"><Package /></span>
                    <span className="menu-title">Gallery</span>
                    </Link>
                </NavItem> */}
                    <NavItem className={`${urlPath.pathname === '/banner' ? 'active' : ''}`}>
                        <Link to='/banner' className="d-flex align-items-center nav-link font-weight-500">
                            <span className="me-2 menu-icon"><Layers /></span>
                            <span className="menu-title">Banner</span>
                        </Link>
                    </NavItem>
                    <NavItem className={`${urlPath.pathname === '/sitesetting' ? 'active' : ''}`}>
                        <Link to='/sitesetting' className="d-flex align-items-center nav-link font-weight-500">
                            <span className="me-2 menu-icon"><Tool /></span>
                            <span className="menu-title">Site Setting</span>
                        </Link>
                    </NavItem>
                    <NavItem className={`${urlPath.pathname === '/cms' ? 'active' : ''}`}>
                        <Link to='/cms' className="d-flex align-items-center nav-link font-weight-500">
                            <span className="me-2 menu-icon"><FileText /></span>
                            <span className="menu-title">CMS Pages</span>
                        </Link>
                    </NavItem>
                    <NavItem className={`${urlPath.pathname === '/speakers' ? 'active' : ''}`}>
                        <Link to='/speakers' className="d-flex align-items-center nav-link font-weight-500">
                            <span className="me-2 menu-icon"><User /></span>
                            <span className="menu-title">Speakers</span>
                        </Link>
                    </NavItem>
                    <NavItem className={`${urlPath.pathname === '/partners' ? 'active' : ''}`}>
                        <Link to='/partners' className="d-flex align-items-center nav-link font-weight-500">
                            <span className="me-2 menu-icon"><UserCheck /></span>
                            <span className="menu-title">Partners</span>
                        </Link>
                    </NavItem>
                    <NavItem className={`${urlPath.pathname === '/popup' ? 'active' : ''}`}>
                        <Link to='/popup' className="d-flex align-items-center nav-link font-weight-500">
                            <span className="me-2 menu-icon"><MessageSquare /></span>
                            <span className="menu-title">Manage Popup</span>
                        </Link>
                    </NavItem>
                    <NavItem className={`${urlPath.pathname === '/registrationList' ? 'active' : ''}`}>
                        <Link to='/registrationList' className="d-flex align-items-center nav-link font-weight-500">
                            <span className="me-2 menu-icon"><List /></span>
                            <span className="menu-title">Registration</span>
                        </Link>
                    </NavItem>
                    {parseInt(props.user.id) === 1 &&
                        <NavItem className={`${urlPath.pathname === '/users' ? 'active' : ''}`}>
                            <Link to='/users' className="d-flex align-items-center nav-link font-weight-500">
                                <span className="me-2 menu-icon"><UserPlus /></span>
                                <span className="menu-title">Manage Admin</span>
                            </Link>
                        </NavItem>
                    }
                </Nav>
            </div>
        </div>
    );
}

export default Sidebar;