import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Card,
  CardHeader,
  CardTitle,
  Button,
  Spinner,
} from "reactstrap";
import AddEvent from "./AddEvent";
import http from "../../CommonAxios";
import { checkData, checkUndeNullBlank } from "../../ObjectExist";
import EditEvent from "./EditEvent";
import { Link } from "react-router-dom";
import PermissionNotAllowed from "./PermissionNotAllowed";
import AddEventItem from "./AddEventItem";

const Event = (props) => {
  const [addmodal, setAddmodal] = useState(false);
  const toggleAdd = () => setAddmodal(!addmodal);
  const [loader, setLoader] = useState(false);
  const [apiData, setApiData] = useState({});
  const [editmodal, setEditmodal] = useState(false);
  const [itemmodal, setItemmodal] = useState(false);
  const [editData, setEditData] = useState({});
  const [eventId, setEventId] = useState({});

  const toggleItem = (id) => {
    setItemmodal(!itemmodal);
    setEventId(id);
  };
  const toggleEdit = (id) => {
    setEditData({});
    setEditmodal(!editmodal);
    let data = { admin_id: checkData(props.user) ? props.user.id : "", id: id };
    setLoader(true);
    http
      .post(`event/view`, data)
      .then((res) => {
        setLoader(false);
        if (res.data.s === 1) {
          res.data.data[0].status === true
            ? (res.data.data[0].status = "1")
            : (res.data.data[0].status = "0");
          setEditData(res.data.data[0]);
        }
      })
      .catch(function (err) {
        setLoader(false);
      });
  };
  const onApiInteg = () => {
    let data = { admin_id: checkData(props.user) ? props.user.id : "" };
    setLoader(true);
    http
      .post(`event/list`, data)
      .then((res) => {
        setLoader(false);
        if (res.data.s === 1) {
          setApiData(res.data.data);
        }
      })
      .catch(function (err) {
        setLoader(false);
      });
  };
  useEffect(() => {
    console.log("calll");
    onApiInteg();
  }, []);

  return (
    <div className="app-body overflow-hidden">
      {loader && (
        <div className="formLoader">
          <Spinner />
        </div>
      )}
      <AddEvent
        user={props.user}
        addmodal={addmodal}
        toggleAdd={toggleAdd}
        setAddmodal={setAddmodal}
        onApiInteg={onApiInteg}
      />
      <AddEventItem
        user={props.user}
        itemmodal={itemmodal}
        toggleItem={toggleItem}
        setItemmodal={setItemmodal}
        onApiInteg={onApiInteg}
        eventId={eventId}
      />
      {checkData(editData) && (
        <EditEvent
          user={props.user}
          addmodal={editmodal}
          toggleAdd={toggleEdit}
          editData={editData}
          setEditmodal={setEditmodal}
          onApiInteg={onApiInteg}
        />
      )}
      <Card className="border-0 shadow mb-3">
        <CardHeader className="page-header content-header d-flex flex-wrap justify-content-between align-items-center">
          <CardTitle className="page-title mb-3 mb-sm-0" tag="h3">
            Manage Events
          </CardTitle>
          {checkData(props.permissions) && props.permissions.Events.add && (
            <Button
              color="primary"
              className="btn-grad border-0"
              onClick={toggleAdd}
            >
              Add Event
            </Button>
          )}
        </CardHeader>
      </Card>
      {checkData(props.permissions) && props.permissions.Events.list ? (
        <Row>
          {checkData(apiData)
            ? apiData.map((item, index) => (
                <div className="col-md-6 col-lg-6">
                  <div
                    className={`card events-card my-3 ${
                      item.status ? "" : "border border-danger"
                    }`}
                  >
                    <div className="card-header d-flex flex-wrap justify-content-between align-items-center">
                      <h3 className="mt-0">{item.event_title}</h3>
                      {checkData(props.permissions) &&
                        props.permissions.Events.edit && (
                          <Button
                            color="primary"
                            className="vs-btn border-0"
                            onClick={() => toggleEdit(item.id)}
                          >
                            Edit Event
                          </Button>
                        )}
                    </div>
                    <div className="event-card-img">
                      {checkUndeNullBlank(item.image) ? (
                        <img
                          class="img-fluid w-100"
                          src={process.env.REACT_APP_IMAGE_EVENT + item.image}
                          alt="placeholder image"
                        />
                      ) : (
                        <img
                          class="img-fluid w-100"
                          src="./img/event-main.jpg"
                          alt="placeholder image"
                        />
                      )}
                      <h4>{item.event_title}</h4>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-auto">
                          <h5>Date</h5>
                          <p>{item.event_start_date}</p>
                        </div>
                        <div className="col-auto">
                          <h5>Location</h5>
                          <p>{item.location}</p>
                        </div>
                        <div className="col-auto">
                          <h5>Status</h5>
                          <p>{item.status == true ? "Active" : "Inactive"}</p>
                        </div>
                        {/*<div className="col-auto">
                                            <h5>Email</h5>
                                            <p>{item.organizer_email}</p>
                                        </div> */}
                      </div>
                    </div>
                    <div className="card-footer">
                      <p>{item.event_small_desc} </p>
                    </div>
                    <div className="py-3 ms-3 w-100">
                      {checkData(props.permissions) &&
                        props.permissions.Event_Gallery.add && (
                          <Button
                            color="primary"
                            className="vs-btn border-0 mx-3"
                          >
                            <Link
                              to={`/gallery?s=${item.id}`}
                              className="text-white"
                            >
                              Add Gallery
                            </Link>
                          </Button>
                        )}
                      {checkData(props.permissions) &&
                        props.permissions.Event_Items.add && (
                          <Button color="primary" className="vs-btn border-0">
                            <Link
                              to={`/eventitem?s=${item.id}`}
                              className="text-white"
                            >
                              Add Event Item
                            </Link>
                          </Button>
                        )}
                    </div>
                  </div>
                </div>
              ))
            : ""}
        </Row>
      ) : (
        <PermissionNotAllowed />
      )}
    </div>
  );
};

export default Event;
