import React from "react";
import { Spinner } from "reactstrap";

const VideoPlayer = () => {
  return (
    <section class="space-top space-extra-bottom">
      <div class="container">
        <div class="title-area text-center">
          {/* <span class="sub-title3">Get Started</span> */}
          <h2 class="sec-title-ndm">
            Highlights of the previous 54th IVC in Mumbai 
          </h2>
          <div class="line"></div>
        </div>

        <div class="row">
          <div class="col-xl-12 col-lg-12 justify-content-center d-flex">
            <div class="map-slide-wrap col-xl-9">
              <div
                class="vs-carousel slick-initialized slick-slider"
                data-asnavfor="#locationSlide"
                id="mapSlide"
                data-fade="true"
                data-slide-show="1"
                data-md-slide-show="1"
              >
                <div class="slick-list draggable">
                  <div class="slick-track" style={{ opacity: 1, width: "" }}>
                    <iframe
                      width="700"
                      height=""
                      src="https://www.youtube.com/embed/Irky3AhbyS0?si=yNW4zNJqEpi6zd-z&autoplay=1&controls=0&loop=1&mute=1"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerpolicy="strict-origin-when-cross-origin"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default VideoPlayer;
