import React, { useState, useEffect } from 'react'
import { Container, Row, Card, CardHeader, CardTitle, CardBody, Table, Spinner } from 'reactstrap'

import http from '../../CommonAxios'
import { checkData } from '../../ObjectExist';


const ContactList = () => {
    const [loader, setLoader] = useState(false);
    const [apiData, setApiData] = useState({});
   
    const onApiInteg = () => {
        let data = { admin_id: 1 }
        setLoader(true);
        http.post(`contact/list`, data).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {
                setApiData(res.data.data)
            }
        }).catch(function (err) {
            setLoader(false);
        });
    };
    useEffect(() => {
        onApiInteg();
    }, [])
    

    return (
        <div className="app-body overflow-hidden">
        {loader && <div className="formLoader"><Spinner /></div>}
            <Card className="border-0 shadow mb-3">
                <CardHeader className="page-header content-header d-flex flex-wrap justify-content-between align-items-center">
                    <CardTitle className="page-title mb-3 mb-sm-0" tag="h3">Contact List</CardTitle>
                </CardHeader>
            </Card>
            <Row>
                <div class="col-md-12 col-lg-12">
                    <div class="card events-card my-3">
                        <CardBody>
                            <Table responsive className="text-center custom-table">
                                <thead>
                                    <tr>
                                        <th className="text-nowrap">Title</th>
                                        <th className="text-nowrap">Slug</th>
                                        <th className="text-nowrap">Created At</th>
                                        <th className="text-nowrap">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* {checkData(apiData) ? apiData.map((item, index) => (
                                        <tr>
                                            <td>{item.title}</td>
                                            <td>{item.slug}</td>
                                            <td>{item.created_at}</td>
                                            <td><Button color="primary" className="vs-btn border-0" onClick={()=>toggleEdit(item.id)}>Edit</Button></td>
                                        </tr>
                                    )) : ''} */}
                                </tbody>
                            </Table>
                        </CardBody>
                    </div>
                </div>
            </Row>
        </div>
    )
}

export default ContactList