import React, { useState, useEffect } from 'react'
import { Container, Row, Card, CardHeader, CardTitle, Button } from 'reactstrap'
import http from '../../CommonAxios'
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import AddGallery from './AddGallery';
import EditGallery from './EditGallery';
import PermissionNotAllowed from './PermissionNotAllowed';


const Gallery = (props) => {
    const [addmodal, setAddmodal] = useState(false);
    const [apiData, setApiData] = useState({});
    const [loader, setLoader] = useState(false);
    const [editmodal, setEditmodal] = useState(false);
    const [editData, setEditData] = useState({});

    const toggleAdd = () => setAddmodal(!addmodal)
    let eventId = (new URLSearchParams(window.location.search)).get("s");
    const toggleEdit = (id) => {
        setEditData({})
        setEditmodal(!editmodal);
        let data = { admin_id: checkData(props.user) ? props.user.id : '', id: id }
        setLoader(true);
        http.post(`event/gallery/view`, data).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {
                res.data.data[0].status === true ? res.data.data[0].status = '1' : res.data.data[0].status = '0'
                setEditData(res.data.data[0])
            }
        }).catch(function (err) {
            setLoader(false);
        });
    }

    const onApiInteg = () => {
        let data = { admin_id: checkData(props.user) ? props.user.id : '', event_id: parseInt(eventId) }
        setLoader(true);
        http.post(`event/gallery/list`, data).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {
                setApiData(res.data.data)
            }
        }).catch(function (err) {
            setLoader(false);
        });
    };
    useEffect(() => {
        onApiInteg();
    }, []);
    return (
        <div className="app-body overflow-hidden">
            <AddGallery user={props.user} addmodal={addmodal} toggleAdd={toggleAdd} eventId={eventId} setAddmodal={setAddmodal} onApiInteg={onApiInteg} />
            {checkData(editData) &&
                <EditGallery user={props.user} editmodal={editmodal} toggleEdit={toggleEdit} eventId={eventId} editData={editData} setEditmodal={setEditmodal} onApiInteg={onApiInteg} />
            }
            <Card className="border-0 shadow mb-3">
                <CardHeader className="page-header content-header d-flex flex-wrap justify-content-between align-items-center">
                    <CardTitle className="page-title mb-3 mb-sm-0" tag="h3">Manage Event Gallery</CardTitle>
                    {checkData(props.permissions) && props.permissions.Event_Gallery.add &&
                        <Button color="primary" className="vs-btn border-0" onClick={toggleAdd}>Add Event Gallery</Button>

                    }
                </CardHeader>
            </Card>
            {(checkData(props.permissions) && props.permissions.Event_Gallery.list)?
                <Row>
                    {checkData(apiData) ? apiData.map((item, index) => (
                        <div class="col-md-6 col-lg-6">
                            <div className={`card events-card my-3 ${item.status ? '' : 'border border-danger'}`} >
                                <div class="card-header d-flex flex-wrap justify-content-between align-items-center">
                                    <h3 class="mt-0">{item.title}</h3>
                                    {checkData(props.permissions) && props.permissions.Event_Gallery.edit &&
                                        <Button color="primary" className="vs-btn border-0" onClick={() => toggleEdit(item.id)}>Edit Event</Button>
                                    }
                                </div>
                                <div class="event-card-img">
                                    {checkUndeNullBlank(item.image) ?
                                        <img class="img-fluid w-100" src={process.env.REACT_APP_IMAGE_EVENT_GALLERY + item.image} alt="placeholder image" />
                                        :
                                        <img class="img-fluid w-100" src="./img/event-main.jpg" alt="placeholder image" />
                                    }
                                </div>
                            </div>
                        </div>
                    )) : ''}
                </Row>
              :
              <PermissionNotAllowed/> }
        </div>
    )
}

export default Gallery