import React, { useState, useRef } from 'react'
import { Row, Col, Card, CardBody, Button, CardTitle, Spinner, FormGroup, Form, CardHeader, Table, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, DropdownToggle, DropdownMenu, UncontrolledDropdown, Label } from 'reactstrap';
import http from '../../CommonAxios'
import Swal from 'sweetalert2';
import { useForm } from 'react-hook-form';
import { DateRangePicker } from 'rsuite';
import moment from 'moment';
import { checkData } from '../../ObjectExist';

const AddEvent = (props) => {
    const [image, setImage] = useState("");
    const [loader, setLoader] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [eventDate, setEventDate] = useState([]);
    const dateRangeChange = (date) => {
        setEventDate(date);
        let sDate = checkData(date) ? moment(date[0]).format('YYYY-MM-DD') : '';
        let eDate = checkData(date) ? moment(date[1]).format('YYYY-MM-DD') : '';
        setStartDate(sDate);
        setEndDate(eDate)

    }
    const editorRef = useRef(null);
    const log = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    };

    const handleFileInput = (e) => {
        setImage(e.target.files[0])
    }
    const { register: addInput, handleSubmit: addSubmit, formState: { errors }, reset } = useForm();

    const onaddEvent = (data) => {
        setLoader(true);
        let formData = new FormData();
        formData.append("admin_id", checkData(props.user) ? props.user.id : '');
        formData.append("event_title", data.event_title);
        formData.append("location", data.location);
        formData.append("event_small_desc", data.event_small_desc);
        formData.append("status", data.status);
        formData.append("event_main_image", image);
        formData.append("event_start_date", startDate);
        formData.append("event_end_date", endDate);

        http.post(`event/add`, formData).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {
                Swal.fire({ title: 'Success', text: res.data.msg, icon: 'success', });
                props.setAddmodal(false); props.onApiInteg();
                setStartDate("");setEndDate("");setEventDate([]);
                reset();
            } else {
                Swal.fire({ icon: 'error', title: 'Error', text: res.data.message, });
            }
        }).catch(function (err) {
            setLoader(false);
        });
    };
    return (
        <>
            {loader && <div className="formLoader"><Spinner /></div>}
            <Modal isOpen={props.addmodal} toggle={props.addmodal} scrollable={true} size="lg" className='addModal'>
                <ModalHeader toggle={props.toggleAdd}>Add New Events</ModalHeader>
                <ModalBody>
                    <Form onSubmit={addSubmit(onaddEvent)}>
                        <Row className="mx-0  addModal">
                            <Col sm={6}>
                                <FormGroup>
                                    <Label for="event_title">Enter Event title<span className="text-danger">*</span></Label>
                                    <input {...addInput("event_title", { required: 'Please Enter Title' })} className="form-control-sm form-control" placeholder="Enter Event title*" id="eventtitle" />
                                    {errors.event_title && <span className="text-danger d-block error">{errors.event_title.message}</span>}
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label for="location">Enter Location<span className="text-danger">*</span></Label>
                                    <input {...addInput("location", { required: 'Please Enter Title' })} className="form-control-sm form-control" placeholder="Enter Location*" id="location" />
                                    {errors.location && <span className="text-danger d-block error">{errors.location.message}</span>}
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label for="uploadLogo_add">Upload Image</Label>
                                    <input {...addInput("bannerImage", { required: false })} type="file" onChange={handleFileInput} accept=".jpg, .jpeg, .png" className="form-control-sm form-control" id="uploadLogo_add" />
                                    <span className="text-info d-block">600/300</span>
                                    {errors.bannerImage && <span className="text-danger d-block error">{errors.bannerImage.message}</span>}
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup className='eventDateinput'>
                                    <Label for="uploadLogo_add">Date</Label>
                                    <DateRangePicker value={eventDate} onChange={date => { dateRangeChange(date) }} placeholder="Enter Date" format="dd-MM-yyyy" />
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label for="small_description">Enter Small Description<span className="text-danger">*</span></Label>
                                    <textarea {...addInput("event_small_desc", { required: 'Please Enter small Description' })} rows={3} className="form-control-sm form-control" placeholder="Enter Small Description*" id="small_descrption" />
                                    {errors.event_small_desc && <span className="text-danger d-block error">{errors.event_small_desc.message}</span>}
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <FormGroup check className="pl-0">
                                        <Label>Status<span className="text-danger">*</span></Label>
                                        <div>
                                            <span className="form-check2 me-3">
                                                <input className="mx-2" {...addInput("status", { required: true })} type="radio" value="1" id="active_add" defaultChecked />
                                                <Label for="active_add">Active</Label>
                                            </span>
                                            <span className="form-check2">
                                                <input className="mx-2" {...addInput("status", { required: true })} type="radio" value="0" id="inactive_add" />
                                                <Label for="inactive_add">InActive</Label>
                                            </span>
                                        </div>
                                    </FormGroup>
                                </FormGroup>
                            </Col>
                            <Col sm={12} className="text-right">
                                <Button className="vs-btn border-0" color="primary">Save</Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}

export default AddEvent